import React, { useState } from "react";
import styles from "./style.module.css";
import { GET_REPORT_API, REPORTS_BY_TIMEFRAME } from "../utils/constant";
import useApiServices from "../utils/useApiServices";
// import Dropdown  from "./Dropdown";
import DropdownReports from "./DropdownReports";
const FloatingReports = ({
  mid,
  reportType,
  setData,
  setCondition,
  reset,
  type,
}) => {
  const { apiCallFnforPost } = useApiServices();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={styles.flot_cont}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "9999",
        width: isOpen ? "700px" : "100px",
        transition: "width 0.3s ease",
        overflow: "visible",
      }}
    >
      <div
        className="bg-secondary rounded rounde-5"
        style={{
          height: "50px",
          width: "auto",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          // padding: "0px 10px"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <DropdownReports
          className="w-100"
          isOpen={isOpen}
          setCondition={setCondition}
          reset={reset}
          type={type}
        />
      </div>
    </div>
  );
};

export default FloatingReports;

import axios from "axios";
import swal from "sweetalert";

import {
  CHECK_FILE_IS_PDF,
  GET_UPLOADED_PDF_FILES,
  GET_ALL_MERCHANT_PAYOUT_API_TEST,
  FETCH_ACCOUNT_DETAILS_BY_MID,
  FETCH_ALL_ACCOUNT_DETAILS,
  FETCH_ALL_BANK_FORM_SUBMITTED,
  FETCH_ALL_FRM_SUBMITTED_FORM,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FETCH_BANK_FORM_SUBMITTED_BY_MID,
  FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT,
  FETCH_FRM_SUBMITTED_FORM_BY_MID,
  GET_RECONCILIATION_DATA,
  SWIPELINC_API,
  UPLOAD_NODAL_FILE,
  UPLOAD_NODAL_TRUE_OR_FALSE,
  UPLOAD_SWITCH_FILE,
  GET_TXN_REPORT_ADVANCE_FILTER,
  PAYIN_FILTER_API,
  SETTLEMENT_MULTIPLE_FILTER_API,
  CHARGEBACK_MULTIPLE_FILTER_API,
  PAYOUT_MULTIPLE_FILTER_API,
  WITHDRAW_MULTIPLE_FILTER_API,
  GURANTEE_MULTIPLE_FILTER_API,
  DISABLED_DATA_PAYOUT_MASTER,
  SAVE_MERCHANT_PAYOUT_MASTER,
  GET_ALL_MECHANT_PAYOUT_MASTERS,
  GET_ALL_ROUTING_VIEW,
  GET_WALLET_BALANCE_BY_MID,
  SID_DETAILS,
  GET_ALL_SUBMITTED_PAYOUT_ROUTING,
  GET_ALL_LIVE_TRACKING_DATA_BOX,
  GET_ALL_LIVE_TRACKING_DATA_TABLE,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX,
  GET_ALL_DAILY_TRANSACTION_DATA_TABLE,
  GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID,
  GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION,
  UPDATE_MERCHANT_BANK_DETAILS,
  ACC_NUM_IFSC_VERIFY_API,
  IFSC_VERIFY_API,
  VERIFY_MERCHANT_ONBOARD_MOBILE,
  VERIFY_MERCHANT_ONBOARD_EMAIL,
  GET_ALL_ORGANISATIONAL_TYPE,
  SAVE_ORGANISATIONAL_TYPE,
  GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  SUBMITTED_VEHICLE_COMMERCIAL,
  SUBMITTED_VEHICLE_TYPE,
  SUBMITTED_VEHICLE_SUBTYPE,
  GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICAL_SETTLEMENT_TYPE,
  GET_ALL_VEHICAL_COMMERCIAL_TYPE,
  SAVE_VEHICAL_SETTLEMENT_TYPE,
  SAVE_VEHICAL_COMMERCIAL_TYPE,
  GET_ALL_VEHICAL_MASTER_FORMS,
  SAVE_VEHICLE_MASTER_FORM,
  SAVE_VEHICLE_MASTER_UPDATE_FORM,
  SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM,
  GET_ALL_MERCHANT_PHASE_2_APPROVED,
  GET_LIVE_TXN_REPORT_ADVANCE_FILTER,
  LIVE_PAYIN_MORE_FILTER_API,
  GET_ALL_TEMPORARY_REQUEST_DATA,
  GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION,
  GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL,
  GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK,
  GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT,
  GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID,
  GET_ALL_DAILY_TRANSACTION_CHARGEBACK_,
  GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID,
  GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID,
  GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID,
  LIVE_WITHDRAWL_MULTIPLE_FILTER_API,
  LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
  CHECK_CAKKBACK_PAYIN,
  CALLBACK_PAYIN_BY_MID,
  CHECK_CALLBACK_FOR_PAYOUT,
  CALLBACK_PAYOUT_BY_MID,
  GET_ALL_MERCHANT_MASTER,
  GET_ALL_AGGREGATOR_DATA,
  GET_ALL_SWITCH_DATA,
  GET_ALL_BANK,
  GET_ALL_SCHEME,
  GET_TXN_REPORT,
  CHECK_CALLBACK_PAYIN,
  DISABLE_VEHICLE_MASTER,
  ENABLE_VEHICLE_MASTER,
  GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER,
  VEHICLE_MASTER_PHASE_2_SAVE,
  VEHICLE_MASTER_PHASE_2_SUBMITTED,
  VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID,
  SUBMITTED_PAYOUT,
  FETCH_PAYOUT_DETAILS,
  APPROVE_REJECT_PAYOUT,
  SINGLE_PAYIN_REPORT,
  FETCH_WITHDRAW_AMOUNT_API,
  SAVE_WITHDRAWL_PERCENTAGE,
  FETCH_ALL_MERCHANT_PAYIN_ROUTING_ONBORDING,
  FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING,
  FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING,
  FETCH_ALL_SUBMITTED_EKYC_ROUTING,
  FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS,
  AGGREGATOR_SAVE,
  AGGREGATOR_UPDATE,
  AGGREGATOR_USERS_SAVE,
  USER_UPDATE,
  GET_ALL_SUB_USER,
  FRM_BLOCK_USER,
  FETCH_FRM_USERS,
  FETCH_MERCHANT_FOR_MASTERS_FORMS,
  GET_ALL_BY_ID_USING_GET,
  GET_ALL_PHASE2_SUBMITTER,
  VEHICLE_MASTER_PHASE_2_UPDATE,
  GET_ALL_VEHICAL_MASTER_FORMS_EKYC,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER,
  PAYIN_FILTER_API_CSV_DOWNLOAD,
  LIVE_PAYIN_MORE_FILTER_API_CSV_DOWNLOAD,
  API_FOR_ADVANCE_FILTER,
  API_FOR_MULTIPLE_FILTER,
  LIVE_GUARANTEE_MULTIPLE_FILTER_API,
  CHANGE_TXN_STATUS_PAYOUT,
} from "./constant";
import axiosInstance from "./axiosInstance";
import Reconciliation from "../Components/Reports/Reconciliation";
import {
  convertDateFormat,
  convertTime,
  getTimeWithoutMillisecondsAndDate,
  paginationCountCalc,
} from "./helper";
export const FETCH_ALL_SID_MASTER_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;
export const checkIsFileIsPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${CHECK_FILE_IS_PDF}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response?.data?.statusCode);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const WithdrawType = async (token) => {
  try {
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal-type/getAllwithdrawal-type`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const MDRTypeFetch = async (mid, token) => {
  try {
    console.log(mid);
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAllServices/${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const MDRPercentageFetchByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getAllMdrByMid/${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllMerchantPhase2Approved = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getMechantForPayinOnBoarding = async (token) => {
  try {
    const response = await axios.get(
      FETCH_ALL_MERCHANT_PAYIN_ROUTING_ONBORDING,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAllAccountdetails = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_ACCOUNT_DETAILS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response?.data );
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAccountDetailsByMid = async (mid, token) => {
  try {
    const response = await axios.get(`${FETCH_ACCOUNT_DETAILS_BY_MID}/${mid}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchDocsByMidForaccounts = async (mid, token) => {
  try {
    const response = await axios.get(
      `${FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllFrmsubmittedForm = async (token) => {
  try {
    const response = await axios.get(`${FETCH_ALL_FRM_SUBMITTED_FORM}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllBankSubmittedForm = async (token) => {
  try {
    const response = await axios.get(`${FETCH_ALL_BANK_FORM_SUBMITTED}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchFrmsubmittedFormByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      `${FETCH_FRM_SUBMITTED_FORM_BY_MID}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchBankFormByMid = async (mid, token) => {
  try {
    console.log(mid);
    const response = await axios.get(
      `${FETCH_BANK_FORM_SUBMITTED_BY_MID}${mid}`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchAllMerchantValidations = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadMerchantDocs = async (mid, docsName, docsFile, token) => {
  console.log(token);
  try {
    const formData = new FormData();
    formData.append("mid", mid);

    docsFile.forEach((file) => {
      formData.append(`pdfFiles`, file);
    });
    docsName.forEach((name) => {
      formData.append(`fileName`, name);
    });

    const response = await axios.post(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/savemerchantdocuments`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response);
    return response?.data?.statusCode;
  } catch (error) {
    console.error(error);
  }
};

export const getAllUsers = async (URL, masterId) => {
  try {
    const res = await axiosInstance.get(URL + masterId);

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllSIDMasterSubmittedApplications = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_SID_MASTER_DETAILS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response?.data );
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const FetchAllReconcialiation = async (token) => {
  try {
    const response = await axios.get(GET_RECONCILIATION_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const uploadNodalTrueOrFalse = async (token) => {
  try {
    const response = await axios.get(UPLOAD_NODAL_TRUE_OR_FALSE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadSwitchFile = async (File, token) => {
  // alert("called");

  let formData = new FormData();
  formData.append("file", File);

  try {
    const response = await axios.post(UPLOAD_SWITCH_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authentication: "Bearer " + token,
      },
    });
    console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const uploadNodalFile = async (File, token) => {
  // alert("called");
  let formData = new FormData();
  formData.append("file", File);

  try {
    const response = await axios.post(UPLOAD_NODAL_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authentication: "Bearer " + token,
      },
    });
    console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getUploadedPdfFiles = async (token) => {
  try {
    const response = await axios.get(GET_UPLOADED_PDF_FILES, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const USE_PAYIN_REPORT_FILTER_API = async (
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  SIDSearchvalue,
  startAmountSearchValue,
  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setTransactionReportData,
  handelViewTxnBtn
) => {
  try {
    // setShowLoader(true);
    const payload = {
      amount: amountSearchValue ? parseInt(amountSearchValue) : 0,
      bankLocation: bankLocationSearchValue ? bankLocationSearchValue : "",
      customerLocation: custLocationSearchValue ? custLocationSearchValue : "",
      date: dateSearchValue ? dateSearchValue : "",
      endAmount: endAmountSearchValue ? endAmountSearchValue : 0,
      endDate: endDateSearchValue ? endDateSearchValue : "",
      greterAmount: greaterAmountSearchValue ? greaterAmountSearchValue : 0,
      ipAddress: ipAddress,
      lessAmount: lessAmountSearchValue ? lessAmountSearchValue : 0, // Include mobile value
      mid: mid,
      payerVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      paymentType: paymentTypeSearchvalue
        ? paymentTypeSearchvalue.trim(" ")
        : "",
      sid: SIDSearchvalue ? SIDSearchvalue.trim(" ") : "",
      startAmount: startAmountSearchValue ? startAmountSearchValue : 0,
      startDate: startDateSearchValue ? startDateSearchValue : "",
      transactionStatus: transectionStatusSearchValue
        ? transectionStatusSearchValue
        : "",
      txnresponseCode: 0,
    };

    const response = await axios.post(PAYIN_FILTER_API, payload, {
      headers: {
        Authentication: `Bearer ${token}`,
      },
      params: {
        page: 0,
        size: 100,
      },
    });

    console.log("Mmmmore-->", response);
    // setShowLoader(false);

    if (response.data.statusCode === 200) {
      const finalRes = response.data.responseData.map((item, i) => ({
        id: i,
        mid: item.mid,
        paymentType: item.paymentType,
        txn_id: item.txn_id,
        txnDate: item.txnDate,
        txnTime: item.txnTime,
        amount: item.amount,
        sentToBankDate: item.sentToBankDate,
        ackFromBankDate: item.ackFromBankDate,
        qrSentToMerchantDate: item.qrSentToMerchantDate,
        callbackSentToMerchantDate: item.callbackSentToMerchantDate,
        ackReceivedFromMerchantDate: item.ackReceivedFromMerchantDate,
        terminalId: null,
        currency: item.currency,
        txnStatus: item.txnStatus,
        evokTxn: item.evokTxnCode,
        responseDate: item.responseDate,
        custRefNo: item.custRefNo,
        orderNo: item.orderNo,
        userVpa: item.userVpa,
        mobileNo: item.mobileNo,
        email: item.email,
        customerIp: item.customerIp,
        customerLocation: item.customerLocation,
        remark: item.remark,
        customerIfsc: item.customerIfsc,
        bankLocation: item.bankLocation,
        action: (
          <button
            className=" badge badge-rounded badge-warning"
            onClick={() => handelViewTxnBtn(item.orderNo)}
          >
            View TXN Status
          </button>
        ),
      }));
      setTransactionReportData(finalRes);
    } else setTransactionReportData([]);
    // console.log(response)
    // setCustomMultiApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_PAYIN_MORE_FILTER_API = async (
  withOrWithoutMid,
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  SIDSearchvalue,
  startAmountSearchValue,
  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setTransactionReportData,
  pageM,
  sizeM,
  ViewDetailsCallbackResponse,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setSuccessData,
  setTotalTransactionCount,
  setIsAdvance
) => {
  try {
    const payload = {
      amount: amountSearchValue ? parseInt(amountSearchValue) : 0,
      bankLocation: bankLocationSearchValue ? bankLocationSearchValue : "",
      customerLocation: custLocationSearchValue ? custLocationSearchValue : "",
      date: dateSearchValue ? dateSearchValue : "",
      endAmount: endAmountSearchValue ? endAmountSearchValue : 0,
      endDate: endDateSearchValue ? endDateSearchValue : "",
      greterAmount: greaterAmountSearchValue ? greaterAmountSearchValue : 0,
      ipAddress: ipAddress,
      lessAmount: lessAmountSearchValue ? lessAmountSearchValue : 0, // Include mobile value
      mid: mid,
      payerVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      paymentType: paymentTypeSearchvalue
        ? paymentTypeSearchvalue.trim(" ")
        : "",
      sid: SIDSearchvalue ? SIDSearchvalue.trim(" ") : "",
      startAmount: startAmountSearchValue ? startAmountSearchValue : 0,
      startDate: startDateSearchValue ? startDateSearchValue : "",
      transactionStatus: transectionStatusSearchValue
        ? transectionStatusSearchValue
        : "",
      txnresponseCode: 0,
    };
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid ? LIVE_PAYIN_MORE_FILTER_API : PAYIN_FILTER_API,
      payload,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );

    console.log(response);
    //  await setShowLoader(false);

    if (response.data.statusCode === 200) {
      // console.log("This is our Resp==")

      // const ViewDetailsCallbackResponse = (item, name, m) => {
      //   dispatch(setAlertData(item));
      //   dispatch(setCompany(name));
      //   dispatch(setM(m));
      //   dispatch(setShowResponse(true));
      // };
      {
        response?.data?.responseData2?.sumOfSuccessAmountSL !== null
          ? setSuccessAmount(
              response?.data?.responseData2?.sumOfSuccessAmountSL
            )
          : setSuccessAmount(response?.data?.responseData2?.sumOfSuccessAmount);
      }
      {
        response?.data?.responseData2?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.responseData2?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.responseData2?.txnCount)
            );
      }
      {
        response?.data?.responseData2
          ? setSuccessData(response?.data?.responseData2)
          : setSuccessData("");
      }
      {
        response?.data?.responseData2?.totaltxn
          ? setTotalTransactionCount(response?.data?.responseData2?.totaltxn)
          : setTotalTransactionCount("");
      }
      // setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
      const finalRes = response?.data?.responseData?.map((item, i) => ({
        id: i,
        companyName: item.companyName,
        mid: item.mid,
        sid: item.sid,
        txnStatus: item.txnStatus,
        txnRespCode: item.txnRespCode,
        evokTxnCode: item.evokTxnCode,
        paymentType: item.paymentType,
        orderNo: item.orderNo,
        txn_id: item.txn_id,
        txnDate: convertDateFormat(item.txnDate?.split(" ")[0]) || "",
        txnTime: item.txnDate?.split(" ")[1] || "",
        sentToBankDate: item?.sentToBankDate?.split(" ")[1] || "",
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1] || "",
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1] || "",
        responseDate: item?.responseDate?.split(" ")[1] || "",
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1] || "",
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1] || "",
        responseCode: item.responseCode,
        terminalId: item.terminalid,
        amount: item.amount,
        currency: item.currency,
        custRefNo: item.custRefNo,
        userVpa: item.userVpa,
        callbackRespJson: (
          <button
            className="badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.callbackRespJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        serverRespJson: (
          <button
            className="badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.serverRespJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        mobileNo: item.mobileNo,
        email: item.email,
        customerIp: item.customerIp,
        customerLocation: item.customerLocation,
        remark: item.remark,
        customerIfsc: item.customerIfsc,
        bankLocation: item.bankLocation,
      }));
      console.log("This is finalres---------->", finalRes);
      {
      }
      setTransactionReportData(finalRes);
      setShowLoader(false);
      // setTransactionReportData(response?.data?.responseData);
    } else if (response.data.statusCode === 304 || 600) {
      setIsAdvance(true);
      setTransactionReportData([]);
      setSuccessAmount("");
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setTransactionReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_FOR_CSV_DOWNLOAD = async (
  withOrWithoutMid,
  amountSearchValue,
  bankLocationSearchValue,
  custLocationSearchValue,
  dateSearchValue,
  endAmountSearchValue,
  endDateSearchValue,
  greaterAmountSearchValue,
  ipAddress,
  lessAmountSearchValue,
  mid,
  payerVPASearchValue,
  paymentTypeSearchvalue,
  SIDSearchvalue,
  startAmountSearchValue,
  startDateSearchValue,
  token,
  transectionStatusSearchValue,
  setDownloadCSVData
) => {
  try {
    const payload = {
      amount: amountSearchValue ? parseInt(amountSearchValue) : 0,
      bankLocation: bankLocationSearchValue ? bankLocationSearchValue : "",
      customerLocation: custLocationSearchValue ? custLocationSearchValue : "",
      date: dateSearchValue ? dateSearchValue : "",
      endAmount: endAmountSearchValue ? endAmountSearchValue : 0,
      endDate: endDateSearchValue ? endDateSearchValue : "",
      greterAmount: greaterAmountSearchValue ? greaterAmountSearchValue : 0,
      ipAddress: ipAddress,
      lessAmount: lessAmountSearchValue ? lessAmountSearchValue : 0, // Include mobile value
      mid: mid,
      payerVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      paymentType: paymentTypeSearchvalue
        ? paymentTypeSearchvalue.trim(" ")
        : "",
      sid: SIDSearchvalue ? SIDSearchvalue.trim(" ") : "",
      startAmount: startAmountSearchValue ? startAmountSearchValue : 0,
      startDate: startDateSearchValue ? startDateSearchValue : "",
      transactionStatus: transectionStatusSearchValue
        ? transectionStatusSearchValue
        : "",
    };
    console.log("Without--->", withOrWithoutMid);
    const response = await axios.post(
      withOrWithoutMid
        ? LIVE_PAYIN_MORE_FILTER_API_CSV_DOWNLOAD
        : PAYIN_FILTER_API_CSV_DOWNLOAD,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authentication: `Bearer ${token}`,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      setDownloadCSVData(response?.data?.responseData);
    } else {
      setDownloadCSVData([]);
      swal("No Record Found", response.data.message, "info");
    }
  } catch (error) {
    console.log(error);
  }
};

export const txnReportAdvanceFilter = async (
  transactionIdSearchValue,
  orderNumberSearchValue,
  emailSearchValue,
  mobileSearchValue,
  payerVPASearchValue,
  custRefNoSearchValue,
  mid,
  token,
  setApplications,
  handelViewTxnBtn
  // setShowLoader
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      GET_TXN_REPORT_ADVANCE_FILTER,
      {
        amount: "",
        bankLocation: "",
        custIpAddress: "",
        custLocation: "",
        custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim(" ") : "",
        email: emailSearchValue ? emailSearchValue.trim(" ") : "",
        endDate: "",
        ifsc: "",
        mid: mid,
        mobile: mobileSearchValue ? mobileSearchValue.trim(" ") : "",
        orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim(" ") : "",
        paymentType: "",
        startDate: "",
        terminalId: "",
        txnDate: "",

        txnId: transactionIdSearchValue
          ? transactionIdSearchValue.trim(" ")
          : "",
        userVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    console.log("Data--->", response?.data);

    if (response?.data?.statusCode === 200) {
      const finalRes = response?.data?.responseData?.map((item, i) => ({
        id: i,
        mid: item.mid,
        paymentType: item.paymentType,
        txn_id: item.txn_id,
        txnDate: item.txnDate,
        txnTime: item.txnTime,
        amount: item.amount,
        sentToBankDate: item.sentToBankDate,
        ackFromBankDate: item.ackFromBankDate,
        qrSentToMerchantDate: item.qrSentToMerchantDate,
        callbackSentToMerchantDate: item.callbackSentToMerchantDate,
        ackReceivedFromMerchantDate: item.ackReceivedFromMerchantDate,
        terminalId: item.terminalId,
        currency: item.currency,
        txnStatus: item.txnStatus,
        evokTxn: item.evokTxnCode,
        responseDate: item.responseDate,
        custRefNo: item.custRefNo,
        orderNo: item.orderNo,
        userVpa: item.userVpa,
        mobileNo: item.mobileNo,
        email: item.email,
        customerIp: item.customerIp,
        customerLocation: item.customerLocation,
        remark: item.remark,
        customerIfsc: item.customerIfsc,
        bankLocation: item.bankLocation,
        action: (
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => handelViewTxnBtn(item.orderNo)}
          >
            View TXN Status
          </button>
        ),
      }));
      console.log("finalRes-------", finalRes);
      setApplications(finalRes);
    } else {
      setApplications([]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const liveTxnReportAdvanceFilter = async (
  date,
  transactionIdSearchValue,
  orderNumberSearchValue,
  emailSearchValue,
  mobileSearchValue,
  payerVPASearchValue,
  custRefNoSearchValue,
  mid,
  token,
  setTransactionReportData,
  ViewDetailsCallbackResponse,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_LIVE_TXN_REPORT_ADVANCE_FILTER,
      {
        amount: "",
        bankLocation: "",
        custIpAddress: "",
        custLocation: "",
        custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim(" ") : "",
        email: emailSearchValue ? emailSearchValue.trim(" ") : "",
        endDate: "",
        ifsc: "",
        mid: mid,
        mobile: mobileSearchValue ? mobileSearchValue.trim(" ") : "",
        orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim(" ") : "",
        paymentType: "",
        startDate: "",
        terminalId: "",
        txnDate: date,
        txnId: transactionIdSearchValue
          ? transactionIdSearchValue.trim(" ")
          : "",
        userVpa: payerVPASearchValue ? payerVPASearchValue.trim(" ") : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    setShowLoader(false);
    console.log(response?.data?.responseData, "adbanceData888");
    if (response.data.statusCode === 200) {
      const finalRes = response.data.responseData.map((item, i) => ({
        id: i,
        companyName: item.companyName,
        mid: item.mid,
        sid: item.sid,
        txnStatus: item.txnStatus,
        txnRespCode: item.txnRespCode,
        evokTxnCode: item.evokTxnCode,
        paymentType: item.paymentType,
        orderNo: item.orderNo,
        txn_id: item.txn_id,
        txnDate: convertDateFormat(item.txnDate?.split(" ")[0]) || "",
        txnTime: item.txnDate?.split(" ")[1] || "",
        sentToBankDate: item?.sentToBankDate?.split(" ")[1] || "",
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1] || "",
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1] || "",
        responseDate: item?.responseDate?.split(" ")[1] || "",
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1] || "",
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1] || "",
        responseCode: item.responseCode,
        terminalId: item.terminalid,
        amount: item.amount,
        currency: item.currency,
        custRefNo: item.custRefNo,
        userVpa: item.userVpa,
        callbackRespJson: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.callbackRespJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        serverRespJson: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.serverRespJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        mobileNo: item.mobileNo,
        email: item.email,
        customerIp: item.customerIp,
        customerLocation: item.customerLocation,
        remark: item.remark,
        customerIfsc: item.customerIfsc,
        bankLocation: item.bankLocation,
      }));
      // console.log("RESPONSE------>", finalRes);
      // if (response.data.responseData === null) {
      //   setTransactionReportData(finalRes);
      // }
      setTransactionReportData(finalRes);
      // setShowLoader(false);
      setIsAdvance(true);
    } else {
      // setShowLoader(false);
      swal({
        title: "Alert!",
        text: "No Data Found.",
        icon: "warning",
      });
      setTransactionReportData([]);
    }

    // setCustomApiResponse(response?.data?.responseData);
  } catch (error) {
    console.log(error);
    // setShowLoader(false)
  }
};

export const USE_SETTLEMENT_MULTIPLE_FILTER = async (
  endAmount,
  startAmount,
  vpa,
  orderNo,
  txnId,
  endPayinAmount,
  startPayinAmount,
  payinAmount,
  startDate,
  endDate,
  mid,
  token,
  setApplications
) => {
  try {
    // alert(startDate)
    // setShowLoader(true);
    const response = await axios.post(
      SETTLEMENT_MULTIPLE_FILTER_API,
      {
        endAmount: endAmount ? parseInt(endAmount) : 0,
        endDate: endDate,
        endPayinAmount: endPayinAmount ? parseInt(endPayinAmount) : 0,
        mid: mid,
        orderNo: orderNo ? orderNo : "",
        payinAmount: payinAmount ? parseInt(payinAmount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        startDate: startDate,
        startPayinAmount: startPayinAmount ? parseInt(startPayinAmount) : 0,
        txnId: txnId ? txnId : "",
        vpa: vpa ? vpa : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid,
      netAmount: item.netAmount,
      orderNo: item.orderNo,
      txnId: item.txnId,
      vpa: item.vpa,
      settlmentDate: item.settlmentDate,
      settlmentTime: item.settlmentTime,
      gst: item.gst,
      serviceChanrge: item.serviceChanrge,
      serviceChargeAmount: item.serviceChargeAmount,
      gstAmount: item.gstAmount,
      remark: item.remark,
      payinAmount: item.payinAmount,
      txnStatus: item.txnStatus,
      respCode: item.respCode,
      collectionMethod: item.collectionMethod,
      terminalId: item.terminalId,
    }));
    setApplications(finalRes);
    console.log(finalRes);
    // console.log(response?.data?.data, "jhgjgjhfhgcfgh");
    // console.log(setSettlementReportData);
    // setSettlementReportData(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_CHARGEBACK_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  startDate1,
  endDate1,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  setChargeBackReportData,
  EditChargeback,
  setRRNNum,
  setChargeBackAdjDate,
  setEditItem
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: startDate1 ? startDate1 : "",
        chargeBackUploadEndDate: endDate1 ? endDate1 : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      rrn: item.rrn,
      mid: item.mid,
      chargeBackAdjDate: item.chargeBackAdjDate,
      chargeBackAmount: item.chargeBackAmount,
      compensationPenalty: item.txnDate,
      transactionDate: item.transactionDate,
      transactionAmount: item.transactionAmount,
      payerVpa: item.payerVpa,
      payeeBank: item.payeeBank,
      mobileNumber: item.mobileNumber,
      emailId: item.emailId,
      ifscCode: item.ifscCode,
      bankLocation: item.bankLocation,
      payeeIpAddress: item.payeeIpAddress,
      payeeLocation: item.payeeLocation,
      createDate: item.createDate,
      createTime: item.createTime,
      action: (
        <div>
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => {
              EditChargeback(item.mid);
              setRRNNum(item.rrn);
              setChargeBackAdjDate(item.chargeBackAdjDate);
              setEditItem(item);
            }}
          >
            Edit
          </button>
        </div>
      ),
    }));
    setChargeBackReportData(finalRes ? finalRes : []);
    // setShowLoader(false);
    console.log(response?.data?.data, "chargeback - jhgjgjhfhgcfgh");
    // setCustomMultiApiResponse(response?.data?.data ? response?.data?.data : []);
  } catch (error) {
    console.log(error);
  }
};

// Chargeback multiple for live
export const USE_LIVE_CHARGEBACK_MULTIPLE_FILTER_API_MAIN = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  chargeBackUploadStartDate,
  chargeBackUploadEndDate,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  pageM,
  sizeM,
  setChargebackReportData,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? GET_ALL_DAILY_TRANSACTION_CHARGEBACK_
        : CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: chargeBackUploadStartDate
          ? chargeBackUploadStartDate
          : "",
        chargeBackUploadEndDate: chargeBackUploadEndDate
          ? chargeBackUploadEndDate
          : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );

    if (response.data.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      // setSuccessAmount(response ? response?.data?.duplicateData?.sumOfSuccessAmount : 0 )
      // {response?.data?.responseData2?.txnCount !== null ? setTxnSizeM(paginationCountCalc(response?.data?.responseData2?.txnCount)) :setTxnSizeM(paginationCountCalc(response?.data?.responseData2?.totaltxn)) }
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        fullName: item.fullName,
        chargeBackAdjDate: item.chargeBackAdjDate,
        createDate: item.createDate,
        chargeBackAmount: item.chargeBackAmount,
        rrn: item.rrn,
        compensationPenalty: item.compensationPenalty,
        txnDate: item.transactionDate.split(" ")[0], // assuming you want to split date and time
        txnTime: item.transactionDate.split(" ")[1],
        ifscCode: item.ifscCode,
        payerVpa: item.payerVpa,
        payeeBank: item.payeeBank,
        mobileNumber: item.mobileNumber,
        emailId: item.emailId,
        bankLocation: item.bankLocation,
        payeeLocation: item.payeeLocation,
      }));
      setChargebackReportData(response.data ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setChargebackReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};
//chargebac multipal filter for merchanttransactions
export const USE_LIVE_CHARGEBACK_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  chargeBackUploadStartDate,
  chargeBackUploadEndDate,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  pageM,
  sizeM,
  setChargebackReportData,
  setApplications,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? GET_ALL_DAILY_TRANSACTION_CHARGEBACK_
        : CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: chargeBackUploadStartDate
          ? chargeBackUploadStartDate
          : "",
        chargeBackUploadEndDate: chargeBackUploadEndDate
          ? chargeBackUploadEndDate
          : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM,
          size: sizeM,
        },
      }
    );

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid,
      fullName: item.fullName,
      chargeBackAdjDate: item.chargeBackAdjDate,
      createDate: item.createDate,
      chargeBackAmount: item.chargeBackAmount,
      rrn: item.rrn,
      compensationPenalty: item.compensationPenalty,
      txnDate: item.transactionDate.split(" ")[0], // assuming you want to split date and time
      txnTime: item.transactionDate.split(" ")[1],
      ifscCode: item.ifscCode,
      payerVpa: item.payerVpa,
      payeeBank: item.payeeBank,
      mobileNumber: item.mobileNumber,
      emailId: item.emailId,
      bankLocation: item.bankLocation,
      payeeLocation: item.payeeLocation,
    }));
    setChargebackReportData(response.data ? finalRes : []);
    setShowLoader(false);
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};
//chargebac multipal filter for merchanttransactions end

export const USE_LIVE_CHARGEBACK_ADVANCE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  chargeBackUploadStartDate,
  chargeBackUploadEndDate,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  setChargebackReportData,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? GET_ALL_DAILY_TRANSACTION_CHARGEBACK_
        : CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: chargeBackUploadStartDate
          ? chargeBackUploadStartDate
          : "",
        chargeBackUploadEndDate: chargeBackUploadEndDate
          ? chargeBackUploadEndDate
          : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    if (response.data.statusCode === 200) {
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        fullName: item.fullName,
        chargeBackAdjDate: item.chargeBackAdjDate,
        createDate: item.createDate,
        chargeBackAmount: item.chargeBackAmount,
        rrn: item.rrn,
        compensationPenalty: item.compensationPenalty,
        txnDate: item.transactionDate.split(" ")[0], // assuming you want to split date and time
        txnTime: item.transactionDate.split(" ")[1],
        ifscCode: item.ifscCode,
        payerVpa: item.payerVpa,
        payeeBank: item.payeeBank,
        mobileNumber: item.mobileNumber,
        emailId: item.emailId,
        bankLocation: item.bankLocation,
        payeeLocation: item.payeeLocation,
      }));
      setChargebackReportData(response.data ? finalRes : []);
      setShowLoader(false);
      console.log("redddd", finalRes);
    } else {
      setIsAdvance(true);
      setChargebackReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};
// Advance chargeback filter
export const USE_LIVE_CHARGEBACK_ADVANCE_FILTER_API_2 = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  transactionDate,
  startDate,
  endDate,
  chargeBackUploadDate,
  chargeBackUploadStartDate,
  chargeBackUploadEndDate,
  email,
  mobile,
  payerVpa,
  rrn,
  transactionUid,
  mid,
  token,
  setChargebackReportData,
  setApplications,
  EditChargeback,
  setRRNNum,
  setChargeBackAdjDate,
  setEditItem
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? GET_ALL_DAILY_TRANSACTION_CHARGEBACK_
        : CHARGEBACK_MULTIPLE_FILTER_API,
      {
        chargeBackAmount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        chargeBackUploadStartDate: chargeBackUploadStartDate
          ? chargeBackUploadStartDate
          : "",
        chargeBackUploadEndDate: chargeBackUploadEndDate
          ? chargeBackUploadEndDate
          : "",
        chargeBackUploadDate: chargeBackUploadDate ? chargeBackUploadDate : "",
        mid: mid,
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        payerVpa: payerVpa ? payerVpa : "",
        rrn: rrn ? rrn : "",
        transactionUid: transactionUid ? transactionUid : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      rrn: item.rrn,
      mid: item.mid,
      chargeBackAdjDate: item.chargeBackAdjDate,
      chargeBackAmount: item.chargeBackAmount,
      compensationPenalty: item.txnDate,
      transactionDate: item.transactionDate,
      transactionAmount: item.transactionAmount,
      payerVpa: item.payerVpa,
      payeeBank: item.payeeBank,
      mobileNumber: item.mobileNumber,
      emailId: item.emailId,
      ifscCode: item.ifscCode,
      bankLocation: item.bankLocation,
      payeeIpAddress: item.payeeIpAddress,
      payeeLocation: item.payeeLocation,
      createDate: item.createDate,
      createTime: item.createTime,
      action: (
        <div>
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => {
              EditChargeback(item.mid);
              setRRNNum(item.rrn);
              setChargeBackAdjDate(item.chargeBackAdjDate);
              setEditItem(item);
            }}
          >
            Edit
          </button>
        </div>
      ),
    }));
    setApplications(finalRes);
    setChargebackReportData(response.data ? finalRes : []);
    // setShowLoader(false);
    console.log("redddd", finalRes);
  } catch (error) {
    console.log(error);
    // setShowLoader(false);
  }
};

export const HandleStatusUpdate = async (txnStatus, reference) => {
  // alert(txnStatus)
  // Show a Swal input popup to take the remark
  swal({
    title: "Enter your remark",
    content: {
      element: "input",
      attributes: {
        placeholder: "Type your remark here...",
        type: "text",
      },
    },
    buttons: {
      cancel: "Cancel",
      confirm: "OK",
    },
  }).then(async (remark) => {
    if (!remark) {
      swal("Remark is required!", "", "error");
      return;
    }

    // Prepare the payload
    const payload = {
      txnStatus,
      reference,
      remark,
    };

    try {
      // Call the API
      const res = await axios.put(CHANGE_TXN_STATUS_PAYOUT, payload);

      // Show success message
      if (res.data.statusCode === 200) {
        swal("Success", "Status updated successfully!", "success");
      } else {
        swal("Error", "Something went wrong!", "error");
      }
    } catch (error) {
      // Handle API errors
      swal(
        "Error",
        error.response?.data?.message || "An unexpected error occurred!",
        "error"
      );
    }
  });
};

export const USE_PAYOUT_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setPayoutReportData,
  setApplications
) => {
  try {
    // setShowLoader(true);
    console.log("setApplications:", setApplications);
    const response = await axios.post(
      PAYOUT_MULTIPLE_FILTER_API,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid,
      fullName: item.fullName,
      email: item.email,
      category: item.category,
      mobile: item.mobile,
      requestId: item.requestId,
      rrn: item.rrn,
      amount: item.amount,
      upi: item.upi,
      transferType: item.transferType,
      reference: item.reference,
      payoutStatus: item.payoutStatus,
      paymentBy: item.paymentBy,
      merchantCallbackRecived: item.merchantCallbackRecived,
      txnId: item.txnId,
      txnStatus: item.txnStatus,
      rrn: item.rrn,
      createDate: item.createDate,
      createTime: item.createTime,
      action: (
        <select
          className={
            item.txnStatus === "pending"
              ? "badge badge-rounded badge-warning"
              : item.txnStatus === "success"
              ? "badge badge-rounded badge-success"
              : "badge badge-rounded badge-danger"
          }
          onChange={(e) => HandleStatusUpdate(e.target.value, item.reference)}
          value={item.txnStatus}
        >
          <option
            className=" badge badge-rounded badge-success mx-2 px-3 py-2"
            value="success"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            SUCCESS
          </option>
          <option
            className=" badge badge-rounded badge-danger mx-2 px-3 py-2"
            value="failure"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            FAILURE
          </option>
          <option
            className=" badge badge-rounded badge-warning mx-2 px-3 py-2"
            value="pending"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            PENDING
          </option>
        </select>
      ),
    }));
    console.log("finalRes:", finalRes);
    setApplications(finalRes);

    setPayoutReportData(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.error(error);
  }
};

// export const HandleStatusUpdate =async (id,refId) => {
//   const payload={

//   }
//   try {
// const res=await axios.post(,payload)
//   } catch (error) {}
// };
// import swal from 'sweetalert';
// import axios from 'axios';

export const USE_LIVE_PAYOUT_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  pageM,
  sizeM,
  setTransactionReportData,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance,
  ViewDetailsCallbackResponse
  // setIsAdvanceFilterOpen,
) => {
  try {
    // console.log('function ',ViewDetailsCallbackResponse);
    setShowLoader(true);
    const response = await axios.post(
      !withOrWithoutMid
        ? PAYOUT_MULTIPLE_FILTER_API
        : LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );
    // setShowLoader(false);

    if (response.data.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      const finalres = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        createDate: item.createDate,
        txnRequestTime: item?.txnRequestTime?.split(" ")[1] || "",
        txnResponseTime: item?.txnResponseTime?.split(" ")[1] || "",
        callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1] || "",
        callbackSendTime: item?.callbackSendTime?.split(" ")[1] || "",
        merchantcallbackResponseTime:
          item?.merchantcallbackResponseTime?.split(" ")[1] || "",
        // callbackJsonReceived: item.callbackJsonReceived ,
        // callbackJSON: item.callbackJSON ,
        callbackJsonReceived: item?.callbackJson ? "Received" : "Not Received",
        json: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(item.json, item.companyName, item.mid)
            }
          >
            View
          </button>
        ),
        callbackJSON: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.callbackJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        merchantCallbackRecived:
          item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
        merchantcallbackResponse: item.merchantcallbackResponse,
        amount: item.amount,
        accountNumber: item.accountNumber,
        ifscCode: item.ifscCode,
        email: item.email,
        mobile: item.mobile,
        reference: item.reference,
        upi: item.upi,
        createTime: item.createTime,
        rrn: item.rrn,
        txnId: item.txnId,
        transferType: item.transferType,
        paymentBy: item.paymentBy,
        txnStatus: item.txnStatus,
        payoutStatus: item.payoutStatus,
        category: item.category,
        action: (
          <select
            className={
              item.txnStatus === "pending"
                ? "badge badge-rounded badge-warning"
                : item.txnStatus === "success"
                ? "badge badge-rounded badge-success"
                : "badge badge-rounded badge-danger"
            }
            onChange={(e) => HandleStatusUpdate(e.target.value, item.reference)}
            value={item.txnStatus}
          >
            <option
              className=" badge badge-rounded badge-success mx-2 px-3 py-2"
              value="success"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              SUCCESS
            </option>
            <option
              className=" badge badge-rounded badge-danger mx-2 px-3 py-2"
              value="failure"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              FAILURE
            </option>
            <option
              className=" badge badge-rounded badge-warning mx-2 px-3 py-2"
              value="pending"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              PENDING
            </option>
          </select>
        ),
      }));
      setTransactionReportData(response?.data?.data ? finalres : []);
      setShowLoader(false);
      // setIsAdvanceFilterOpen(false);
    }
    // else if(response?.data?.statusCode === 304 || 600 || 300){
    //   setTransactionReportData([{id:1}]);
    //   setSuccessAmount("")
    //   setShowLoader(false);
    // }
    // else{
    //   setSuccessAmount("")
    //   setTransactionReportData([]);
    //   setShowLoader(false)
    // }
    else {
      setSuccessAmount("");
      setIsAdvance(true);
      // setIsAdvanceFilterOpen(false);
      setTransactionReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

// For Payout live filter
export const USE_LIVE_PAYOUT_ADVANCE_FILTER_API_MAIN = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setTransactionReportData,
  setShowLoader,
  setIsAdvanceFilterOpen,
  setIsAdvance,
  setSuccessAmount,
  setTxnSizeM,
  ViewDetailsCallbackResponse
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      !withOrWithoutMid
        ? PAYOUT_MULTIPLE_FILTER_API
        : LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    console.log(response);
    if (response?.data?.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      const finalres = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        createDate: item.createDate,
        txnRequestTime: item?.txnRequestTime?.split(" ")[1] || "",
        txnResponseTime: item?.txnResponseTime?.split(" ")[1] || "",
        callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1] || "",
        callbackSendTime: item?.callbackSendTime?.split(" ")[1] || "",
        merchantcallbackResponseTime:
          item?.merchantcallbackResponseTime?.split(" ")[1] || "",
        // callbackJsonReceived: item.callbackJsonReceived ,
        // callbackJSON: item.callbackJSON ,
        callbackJsonReceived: item?.callbackJson ? "Received" : "Not Received",
        json: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(item.json, item.companyName, item.mid)
            }
          >
            View
          </button>
        ),
        callbackJSON: (
          <button
            className=" badge badge-rounded badge-info mx-2 px-3 py-2"
            onClick={() =>
              ViewDetailsCallbackResponse(
                item.callbackJson,
                item.companyName,
                item.mid
              )
            }
          >
            View
          </button>
        ),
        merchantCallbackRecived:
          item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
        merchantcallbackResponse: item.merchantcallbackResponse,
        amount: item.amount,
        accountNumber: item.accountNumber,
        ifscCode: item.ifscCode,
        email: item.email,
        mobile: item.mobile,
        reference: item.reference,
        upi: item.upi,
        createTime: item.createTime,
        rrn: item.rrn,
        txnId: item.txnId,
        transferType: item.transferType,
        paymentBy: item.paymentBy,
        txnStatus: item.txnStatus,
        payoutStatus: item.payoutStatus,
        category: item.category,
        action: (
          <select
            className={
              item.txnStatus === "pending"
                ? "badge badge-rounded badge-warning"
                : item.txnStatus === "success"
                ? "badge badge-rounded badge-success"
                : "badge badge-rounded badge-danger"
            }
            onChange={(e) => HandleStatusUpdate(e.target.value, item.reference)}
            value={item.txnStatus}
          >
            <option
              className=" badge badge-rounded badge-success mx-2 px-3 py-2"
              value="success"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              SUCCESS
            </option>
            <option
              className=" badge badge-rounded badge-danger mx-2 px-3 py-2"
              value="failure"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              FAILURE
            </option>
            <option
              className=" badge badge-rounded badge-warning mx-2 px-3 py-2"
              value="pending"
              // onClick={() => HandleStatusUpdate(item.reference)}
            >
              PENDING
            </option>
          </select>
        ),
      }));
      console.log(finalres);
      setTransactionReportData(response?.data?.data ? finalres : []);
      setShowLoader(false);
      setIsAdvanceFilterOpen(false);
    } else {
      setSuccessAmount("");
      setIsAdvance(true);
      setTransactionReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

// For Payout Advance filter for Merchant transa..
export const USE_LIVE_PAYOUT_ADVANCE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  setTransactionReportData,
  setApplications,
  setShowLoader
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      !withOrWithoutMid
        ? PAYOUT_MULTIPLE_FILTER_API
        : LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    const finalres = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid || "NA",
      companyName: item.companyName || "NA",
      createDate: item.createDate.split(" ")[0],
      txnRequestTime: item.txnRequestTime || "NA",
      txnResponseTime: item.txnResponseTime || "NA",
      callbackReceiveTime: item.callbackReceiveTime || "NA",
      callbackSendTime: item.callbackSendTime || "NA",
      merchantcallbackResponseTime: item.merchantcallbackResponseTime || "NA",
      callbackJsonReceived: item.callbackJsonReceived || "NA",
      callbackJSON: item.callbackJSON || "NA",
      merchantCallbackRecived: item.merchantCallbackRecived || "NA",
      merchantcallbackResponse: item.merchantcallbackResponse || "NA",
      amount: item.amount || "NA",
      accountNumber: item.accountNumber || "NA",
      ifscCode: item.ifscCode || "NA",
      email: item.email || "NA",
      mobile: item.mobile || "NA",
      reference: item.reference || "NA",
      upi: item.upi || "NA",
      createTime: item.createTime.split(" ")[1],
      rrn: item.rrn || "NA",
      txnId: item.txnId || "NA",
      transferType: item.transferType || "NA",
      paymentBy: item.paymentBy || "NA",
      txnStatus: item.txnStatus || "NA",
      payoutStatus: item.payoutStatus || "NA",
      category: item.category || "NA",
      action: (
        <select
          className={
            item.txnStatus === "pending"
              ? "badge badge-rounded badge-warning"
              : item.txnStatus === "success"
              ? "badge badge-rounded badge-success"
              : "badge badge-rounded badge-danger"
          }
          onChange={(e) => HandleStatusUpdate(e.target.value, item.reference)}
          value={item.txnStatus}
        >
          <option
            className=" badge badge-rounded badge-success mx-2 px-3 py-2"
            value="success"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            SUCCESS
          </option>
          <option
            className=" badge badge-rounded badge-danger mx-2 px-3 py-2"
            value="failure"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            FAILURE
          </option>
          <option
            className=" badge badge-rounded badge-warning mx-2 px-3 py-2"
            value="pending"
            // onClick={() => HandleStatusUpdate(item.reference)}
          >
            PENDING
          </option>
        </select>
      ),
    }));
    setApplications(finalres);
    setTransactionReportData(response?.data?.data ? finalres : []);
    setShowLoader(false);
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

export const USE_RECON_MULTIPLE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION,
      {
        amount: amount ? parseInt(amount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        endDate: endDate ? endDate : "",
        mid: mid,
        payerVpa: upi ? upi : "",
        rrn: rrn ? rrn : "",
        startAmount: startAmount ? parseInt(startAmount) : 0,
        startDate: startDate ? startDate : "",
        txnId: txnId ? txnId : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );
    // setSuccessAmount(response ? response?.data?.duplicateData?.sumOfSuccessAmount : 0 )

    if (response.data.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        extId: item.extId,
        date: item.date,
        rrn: item.rrn,
        amount: item.amount,
        transactionStatus: item.transactionStatus,
        consolidatedStatus: item.consolidatedStatus,
        payinStatus: item.payinStatus,
      }));
      setReconReportData(response?.data?.statusCode === 200 ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setReconReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

//For Wallet Multiple filter

// export const USE_WALLET_MULTIPLE_FILTER_API = async (
//   amount,
//   endAmount,
//   endDate,
//   mid,
//   upi,
//   rrn,
//   startAmount,
//   startDate,
//   txnId,
//   token,
//   setReconReportData,
//   pageM,
//   sizeM,
//   setShowLoader,
//   setSuccessAmount,
//   setTxnSizeM,
//   setIsAdvance,
//   searchOption2
// ) => {
//   try {
//     setShowLoader(true);
//     const payload = {
//       mid: mid,
//       type: "wallet",
//     };

//     if (searchOption2 === "date") {
//       payload.date = startDate || "";
//       payload.startDate = "";
//       payload.endDate = "";// Use startDate as the main date when "date" is selected
//     } else if (searchOption2 === "dateInBetween") {
//       payload.date = "";
//       payload.startDate = startDate || "";
//       payload.endDate = endDate || "";
//     }
//     const response = await axios.post(
//       API_FOR_MULTIPLE_FILTER,
//       payload,
//       {
//         headers: {
//           Authentication: "Bearer " + token,
//         },
//         params: {
//           page: pageM - 1,
//           size: sizeM,
//         },
//       }
//     );

//     if (response.data.statusCode === 200) {
//       setSuccessAmount(
//         response?.data?.duplicateData?.sumOfSuccessAmount ??
//         response?.data?.duplicateData?.sumOfSuccessAmountSL
//       );

//       setTxnSizeM(
//         paginationCountCalc(
//           response?.data?.duplicateData?.totaltxn ??
//           response?.data?.duplicateData?.txnCount
//         )
//       );

//       const finalRes = response?.data?.data?.map((item, i) => ({

//         id: i,
//         mid: item.mid,
//         companyName: item.companyName,
//         amount: item.amount,
//         utr: item.utr,
//         transferMode: item.transferMode,
//         status: item.status,
//         remark: item.remark,
//         serviceCharge: item.serviceCharge,
//         gstCharge: item.gstCharge,
//         actualAmount: item.actualAmount,
//         createDate: item.createDateTime,
//         createTime: item.createTime,
//         transactionDate: item.transactionDate
//       }));

//       setReconReportData(finalRes);
//     } else {
//       setIsAdvance(true);
//       setSuccessAmount("");
//       setReconReportData([]);
//     }
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setShowLoader(false);
//   }
// };

export const USE_WALLET_MULTIPLE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance,
  searchOption2
) => {
  try {
    setShowLoader(true);
    const payload = {
      mid: mid,
      type: "wallet",
    };

    if (searchOption2 === "date") {
      payload.date = startDate || "";
      payload.startDate = "";
      payload.endDate = ""; // Use startDate as the main date when "date" is selected
    } else if (searchOption2 === "dateInBetween") {
      payload.date = "";
      payload.startDate = startDate || "";
      payload.endDate = endDate || "";
    }
    const response = await axios.post(API_FOR_MULTIPLE_FILTER, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page: pageM - 1,
        size: sizeM,
      },
    });

    if (response.data.statusCode === 200) {
      setSuccessAmount(response?.data?.duplicateData);
      // response?.data?.duplicateData?.sumOfSuccessAmount ??
      // // response?.data?.duplicateData?.sumOfSuccessAmountSL

      setTxnSizeM(
        paginationCountCalc(
          response?.data?.duplicateData?.totaltxn ??
            response?.data?.duplicateData?.txnCount
        )
      );

      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        amount: item.amount,
        utr: item.utr,
        transferMode: item.transferMode,
        status: item.status,
        remark: item.remark,
        serviceCharge: item.serviceCharge,
        gstCharge: item.gstCharge,
        actualAmount: item.actualAmount,
        createDate: item.createDateTime?.split(" ")[0],
        createTime: item.createDateTime?.split(" ")[1],
        transactionDate: item.transactionDate,
      }));

      setReconReportData(finalRes);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setReconReportData([]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setShowLoader(false);
  }
};

//For Settlement Multiple Filter
// export const USE_SETTLEMENT_MULTIPLE_FILTER_AV = async (
//   amount,
//   endAmount,
//   endDate,
//   mid,
//   upi,
//   rrn,
//   startAmount,
//   startDate,
//   txnId,
//   token,
//   setReconReportData,
//   pageM,
//   sizeM,
//   setShowLoader,
//   setSuccessAmount,
//   setTxnSizeM,
//   setIsAdvance,
//   searchOption2
// ) => {
//   try {
//     setShowLoader(true);
//     const payload = {
//       mid: mid,
//       type: "settlement",
//     };

//     if (searchOption2 === "date") {
//       payload.date = startDate || "";
//       payload.startDate = "";
//       payload.endDate = "";// Use startDate as the main date when "date" is selected
//     } else if (searchOption2 === "dateInBetween") {
//       payload.date = "";
//       payload.startDate = startDate || "";
//       payload.endDate = endDate || "";
//     }
//     const response = await axios.post(
//       API_FOR_MULTIPLE_FILTER,
//       payload,
//       {
//         headers: {
//           Authentication: "Bearer " + token,
//         },
//         params: {
//           page: pageM - 1,
//           size: sizeM,
//         },
//       }
//     );

//     if (response.data.statusCode === 200) {
//       setSuccessAmount(
//         response?.data?.duplicateData?.sumOfSuccessAmount ??
//         response?.data?.duplicateData?.sumOfSuccessAmountSL
//       );

//       setTxnSizeM(
//         paginationCountCalc(
//           response?.data?.duplicateData?.totaltxn ??
//           response?.data?.duplicateData?.txnCount
//         )
//       );

//       const finalRes = response?.data?.data?.map((item, i) => ({

//         id: i,
//         ...item,
//       }));

//       setReconReportData(finalRes);
//     } else {
//       setIsAdvance(true);
//       setSuccessAmount("");
//       setReconReportData([]);
//     }
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setShowLoader(false);
//   }
// };

//For Settlement Multiple Filter
export const USE_SETTLEMENT_MULTIPLE_FILTER_AV = async (
  amount,
  maxAmount,
  endDate,
  mid,
  upi,
  rrn,
  minAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance,
  searchOption2
) => {
  try {
    setShowLoader(true);

    const payload = {
      amount: "",
      date: "",
      endDate: "",
      maxAmount: "",
      mid: "",
      minAmount: "",
      startDate: "",
      type: "settlement",
    };

    if (amount) {
      payload.amount = amount ? amount : "";
      payload.minAmount = "";
      payload.maxAmount = "";
    } else if (minAmount && maxAmount) {
      payload.amount = "";
      payload.minAmount = minAmount ? minAmount : "";
      payload.maxAmount = maxAmount ? maxAmount : "";
    }

    if (searchOption2 === "date") {
      payload.date = startDate || "";
      payload.startDate = "";
      payload.endDate = "";
    } else if (searchOption2 === "dateInBetween") {
      payload.date = "";
      payload.startDate = startDate || "";
      payload.endDate = endDate || "";
    }
    console.log("Payload:", payload);
    const response = await axios.post(API_FOR_MULTIPLE_FILTER, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page: pageM - 1,
        size: sizeM,
      },
    });

    if (response.data.statusCode === 200) {
      setSuccessAmount(response?.data?.duplicateData);

      setTxnSizeM(
        paginationCountCalc(
          response?.data?.duplicateData?.totaltxn ??
            response?.data?.duplicateData?.txnCount
        )
      );

      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        createDate: item.createDateTime?.split(" ")[0],
        createTime: item.createDateTime?.split(" ")[1],
        // updatedDateTime: convertTimestampToDate(item.updatedDateTime),
        updatedDateTime: item.updatedDateTime,
        updatedBy: item.updatedBy,
        createdBy: item.createdBy,
        mid: item.mid,
        netAmount: item.netAmount,
        orderNo: item.orderNo,
        txnId: item.txnId,
        vpa: item.vpa,
        gst: item.gst,
        serviceChanrge: item.serviceChanrge,
        serviceChargeAmount: item.serviceChargeAmount,
        gstAmount: item.gstAmount,
        remark: item.remark,
        payinAmount: item.payinAmount,
        txnStatus: item.txnStatus,
        respCode: item.respCode,
        collectionMethod: item.collectionMethod,
        terminalId: item.terminalId,
        totalServiceChargeAmount: item.totalServiceChargeAmount,
        holdAmount: item.holdAmount,
        deposoitAmount: item.deposoitAmount,
        holdPercentage: item.holdPercentage,
        depositPercentage: item.depositPercentage,
        settleToWallet: item.settleToWallet,
        deleted: item.deleted,
        companyName: item.companyName,
      }));

      setReconReportData(finalRes);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setReconReportData([]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setShowLoader(false);
  }
};

//For Settlement multiple filter
// export const USE_SETTLEMENT_MULTIPLE_FILTER_API = async (
//   amount,
//   endAmount,
//   endDate,
//   mid,
//   upi,
//   rrn,
//   startAmount,
//   startDate,
//   txnId,
//   token,
//   setReconReportData,
//   pageM,
//   sizeM,
//   setShowLoader,
//   setSuccessAmount,
//   setTxnSizeM,
//   setIsAdvance,
//   searchOption2
// ) => {
//   try {
//     setShowLoader(true);
//     const payload = {
//       mid: mid,
//       type: "wallet",
//     };

//     if (searchOption2 === "date") {
//       payload.date = startDate || "";
//       payload.startDate = "";
//       payload.endDate = "";// Use startDate as the main date when "date" is selected
//     } else if (searchOption2 === "dateInBetween") {
//       payload.date = "";
//       payload.startDate = startDate || "";
//       payload.endDate = endDate || "";
//     }
//     const response = await axios.post(
//       API_FOR_MULTIPLE_FILTER,
//       payload,
//       {
//         headers: {
//           Authentication: "Bearer " + token,
//         },
//         params: {
//           page: pageM - 1,
//           size: sizeM,
//         },
//       }
//     );

//     if (response.data.statusCode === 200) {
//       setSuccessAmount(
//         response?.data?.duplicateData?.sumOfSuccessAmount ??
//         response?.data?.duplicateData?.sumOfSuccessAmountSL
//       );

//       setTxnSizeM(
//         paginationCountCalc(
//           response?.data?.duplicateData?.totaltxn ??
//           response?.data?.duplicateData?.txnCount
//         )
//       );

//       const finalRes = response?.data?.data?.map((item, i) => ({

//         id: i,
//         mid: item.mid,
//         companyName: item.companyName,
//         amount: item.amount,
//         utr: item.utr,
//         transferMode: item.transferMode,
//         status: item.status,
//         remark: item.remark,
//         serviceCharge: item.serviceCharge,
//         gstCharge: item.gstCharge,
//         actualAmount: item.actualAmount,
//         createDate: item.createDateTime,
//         createTime: item.createTime,
//         transactionDate: item.transactionDate
//       }));

//       setReconReportData(finalRes);
//     } else {
//       setIsAdvance(true);
//       setSuccessAmount("");
//       setReconReportData([]);
//     }
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setShowLoader(false);
//   }
// };

export const USE_SETTLEMENT_MULTIPLE_FILTER_API = async (
  amount,
  maxAmount,
  endDate,
  mid,
  upi,
  rrn,
  minAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance,
  searchOption2
) => {
  try {
    setShowLoader(true);
    // const payload = {
    //   mid: mid,
    //   type: "settlement",
    // };

    const payload = {
      amount: "",
      date: "",
      endDate: "",
      maxAmount: "",
      mid: "",
      minAmount: "",
      startDate: "",
      type: "settlement",
    };

    if (amount) {
      payload.amount = amount;
      payload.minAmount = "";
      payload.maxAmount = "";
    } else if (minAmount && maxAmount) {
      payload.amount = "";
      payload.minAmount = minAmount;
      payload.maxAmount = maxAmount;
    }

    if (searchOption2 === "date") {
      payload.date = startDate || "";
      payload.startDate = "";
      payload.endDate = ""; // Use startDate as the main date when "date" is selected
    } else if (searchOption2 === "dateInBetween") {
      payload.date = "";
      payload.startDate = startDate || "";
      payload.endDate = endDate || "";
    }
    const response = await axios.post(API_FOR_MULTIPLE_FILTER, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page: pageM - 1,
        size: sizeM,
      },
    });

    if (response.data.statusCode === 200) {
      setSuccessAmount(
        response?.data?.duplicateData?.sumOfSuccessAmount ??
          response?.data?.duplicateData?.sumOfSuccessAmountSL
      );

      setTxnSizeM(
        paginationCountCalc(
          response?.data?.duplicateData?.totaltxn ??
            response?.data?.duplicateData?.txnCount
        )
      );

      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        amount: item.amount,
        utr: item.utr,
        transferMode: item.transferMode,
        status: item.status,
        remark: item.remark,
        serviceCharge: item.serviceCharge,
        gstCharge: item.gstCharge,
        actualAmount: item.actualAmount,
        createDate: item.createDateTime,
        createTime: item.createTime,
        transactionDate: item.transactionDate,
      }));

      setReconReportData(finalRes);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setReconReportData([]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setShowLoader(false);
  }
};

//For Release Multiple filter
// export const USE_RELEASE_MULTIPLE_FILTER_API = async (
//   amount,
//   endAmount,
//   endDate,
//   mid,
//   upi,
//   rrn,
//   startAmount,
//   startDate,
//   txnId,
//   token,
//   setReconReportData,
//   pageM,
//   sizeM,
//   setShowLoader,
//   setSuccessAmount,
//   setTxnSizeM,
//   setIsAdvance,
//   searchOption2,
//   searchOption
// ) => {
//   try {
//     setShowLoader(true);
//     const payload = {
//       mid: mid,
//       type: "release",
//      minAmount : parseInt(startAmount),
//      maxAmount : parseInt(endAmount),
//      amount: parseInt(amount)
//     };

//     if (searchOption2 === "date") {
//       payload.date = startDate || "";
//       payload.startDate = "";
//       payload.endDate = "";// Use startDate as the main date when "date" is selected
//     } else if (searchOption2 === "dateInBetween") {
//       payload.date = "";
//       payload.startDate = startDate || "";
//       payload.endDate = endDate || "";
//     }
//     const response = await axios.post(
//       API_FOR_MULTIPLE_FILTER,
//       payload,
//       {
//         headers: {
//           Authentication: "Bearer " + token,
//         },
//         params: {
//           page: pageM - 1,
//           size: sizeM,
//         },
//       }
//     );

//     if (response.data.statusCode === 200) {
//       setSuccessAmount(
//         response?.data?.duplicateData?.sumOfSuccessAmount ??
//         response?.data?.duplicateData?.sumOfSuccessAmountSL
//       );

//       setTxnSizeM(
//         paginationCountCalc(
//           response?.data?.duplicateData?.totaltxn ??
//           response?.data?.duplicateData?.txnCount
//         )
//       );

//       const finalRes = response?.data?.data?.map((item, i) => ({

//         id: i,
//         "Date": item?.txnDate?.split(" ")[0],
//         "createDate": item?.createDateTime?.split(" ")[0],
//       }));

//       setReconReportData(finalRes);
//     } else {
//       setIsAdvance(true);
//       setSuccessAmount("");
//       setReconReportData([]);
//     }
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setShowLoader(false);
//   }
// };

function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

//For Release Multiple filter
export const USE_RELEASE_MULTIPLE_FILTER_API = async (
  amount,
  maxAmount,
  endDate,
  mid,
  upi,
  rrn,
  minAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance,
  searchOption2
) => {
  try {
    setShowLoader(true);

    const payload = {
      amount: null,
      date: "",
      endDate: "",
      maxAmount: null,
      mid: "",
      minAmount: null,
      startDate: "",
      type: "release",
    };

    if (amount) {
      payload.amount = amount;
      payload.minAmount = null;
      payload.maxAmount = null;
    } else if (minAmount && maxAmount) {
      payload.amount = null;
      payload.minAmount = minAmount;
      payload.maxAmount = maxAmount;
    }

    if (searchOption2 === "date") {
      payload.date = startDate || "";
      payload.startDate = "";
      payload.endDate = "";
    } else if (searchOption2 === "dateInBetween") {
      payload.date = "";
      payload.startDate = startDate || "";
      payload.endDate = endDate || "";
    }
    console.log("Payload:", payload);
    const response = await axios.post(API_FOR_MULTIPLE_FILTER, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page: pageM - 1,
        size: sizeM,
      },
    });

    if (response.data.statusCode === 200) {
      setSuccessAmount(
        response?.data?.duplicateData?.sumOfSuccessAmount ??
          response?.data?.duplicateData?.sumOfSuccessAmountSL
      );

      setTxnSizeM(
        paginationCountCalc(
          response?.data?.duplicateData?.totaltxn ??
            response?.data?.duplicateData?.txnCount
        )
      );

      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        createDateTime: convertTimestampToDate(item.createDateTime),
        updatedDateTime: convertTimestampToDate(item.updatedDateTime),
        updatedBy: item.updatedBy,
        createdBy: item.createdBy,
        mid: item.mid,
        amount: item.amount,
        releaseFrom: item.releaseFrom,
        remark: item.remark,
        txnDate: item.txnDate,
        rrn: item.rrn,
        transferMode: item.transferMode,
        active: item.active,
        deleted: item.deleted,
      }));
      console.log("release data", finalRes);
      setReconReportData(finalRes);
    } else {
      setIsAdvance(true);
      setSuccessAmount("");
      setReconReportData([]);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setShowLoader(false);
  }
};

// For Reconciliation advance filter
export const USE_RECON_ADVANCE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  txnId,
  token,
  setReconReportData,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION,
      {
        amount: amount ? parseInt(amount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        endDate: endDate ? endDate : "",
        mid: mid,
        payerVpa: upi ? upi : "",
        rrn: rrn ? rrn : "",
        startAmount: startAmount ? parseInt(startAmount) : 0,
        startDate: startDate ? startDate : "",
        txnId: txnId ? txnId : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        extId: item.extId,
        date: item.date,
        rrn: item.rrn,
        amount: item.amount,
        transactionStatus: item.transactionStatus,
        consolidatedStatus: item.consolidatedStatus,
        payinStatus: item.payinStatus,
      }));
      setReconReportData(response?.data?.data ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setReconReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

// For Wallet Advance filter
export const USE_WALLET_ADVANCE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  token,
  setReconReportData,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      API_FOR_ADVANCE_FILTER,
      {
        email: "",
        mobileNo: "",
        orderNo: "",
        txnId: "",
        type: "wallet",
        utr: rrn ? rrn : "",
        vpa: "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        companyName: item.companyName,
        createDate: item.createDateTime,
        transactionDate: item.transactionDate,
        actualAmount: item.actualAmount,
        serviceCharge: item.serviceCharge,
        gstCharge: item.gstCharge,
        transferMode: item.transferMode,
        status: item.status,
        utr: item.utr,
        amount: item.amount,
      }));
      setReconReportData(response?.data?.data ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setReconReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

// For Settlement Advance filter
export const USE_SETTLEMENT_ADVANCE_FILTER = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  token,
  setReconReportData,
  setShowLoader,
  setIsAdvance,
  txnId,
  vpa
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      API_FOR_ADVANCE_FILTER,
      {
        email: "",
        mobileNo: "",
        orderNo: rrn ? rrn : "",
        txnId: txnId ? txnId : "",
        type: "settlement",
        utr: "",
        vpa: vpa ? vpa : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        ...item,
      }));
      setReconReportData(response?.data?.data ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setReconReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

//For Release Advance Filter
export const USE_RELEASE_ADVANCE_FILTER_API = async (
  amount,
  endAmount,
  endDate,
  mid,
  upi,
  rrn,
  startAmount,
  startDate,
  token,
  setReconReportData,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      API_FOR_ADVANCE_FILTER,
      {
        email: "",
        mobileNo: "",
        orderNo: "",
        txnId: "",
        type: "release",
        utr: rrn ? rrn : "",
        vpa: "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      const finalRes = response?.data?.data?.map((item, i) => ({
        id: i,
        ...item,
      }));
      setReconReportData(response?.data?.data ? finalRes : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setReconReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

export const USE_WITHDRAW_MULTIPLE_FILTER_API = async (
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,

  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setShowLoader,
  setWithdrawlReportData,
  setApplications,
  EditWithdrawl,
  setEditItem,
  setUtrNum,
  setTxnDate,
  setIswithdrawlmanual,
  handleDeleteRecord
  // startDate,
  // endDate,
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    if (response?.data?.statusCode === 304) {
      setWithdrawlReportData([]);
      return;
    }

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid,
      actualAmount: item.actualAmount,
      amount: item.amount,
      serviceCharge: item.serviceCharge,
      gstCharge: item.gstCharge,
      utr: item.utr,
      transferType: item.transferType,
      virtualAccountType: item.virtualAccountType,
      status: item.status,
      fullName: item.fullName,
      emailId: item.emailId,
      transactionDate: item.transactionDate,
      createDate: item.createDate,
      createTime: item.createTime,
      action: (
        <div>
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => {
              EditWithdrawl(item.mid);
              setEditItem(item);
              setUtrNum(item.utr);
              setTxnDate(item.transactionDate);
              setIswithdrawlmanual(item.isWithdrawalManual);
            }}
          >
            Edit
          </button>

          {item.isWithdrawalManual && (
            <button
              className="badge badge-rounded badge-danger"
              onClick={() => handleDeleteRecord(item.withdrawalId)}
            >
              Delete
            </button>
          )}
        </div>
      ),
    }));
    // console.log("------", finalRes)
    setApplications(finalRes);
    setWithdrawlReportData(
      response?.data?.statusCode === 200 ? response?.data?.data : []
    );
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_WITHDRAW_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,
  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setWithdrawlReportData,
  pageM,
  sizeM,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? LIVE_WITHDRAWL_MULTIPLE_FILTER_API
        : WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );
    // setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    // if (response?.data?.statusCode === 304) {
    //   setWithdrawlReportData([]);
    //   setShowLoader(false)
    //   return;
    // }
    // setSuccessAmount(response ? response?.data?.duplicateData?.sumOfSuccessAmount : 0 )

    if (response.data.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      const finalres = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        fullName: item.fullName,
        actualAmount: item.actualAmount,
        amount: item.amount,
        transferType: item.transferType,
        gstCharge: item.gstCharge,
        serviceCharge: item.serviceCharge,
        status: item.status,
        emailId: item.emailId,
        utr: item.utr,
        transactionDate: item.transactionDate,
        transferMode: item.virtualAccountType,
        virtualAccountNumber: item.virtualAccountNumber,
        ifsc: item.ifsc,
        bankName: item.bankName,
        // remark: item.remark || "NA",
        createDate: item.createDate.split(" ")[0],
        createTime: item.createTime.split(" ")[1],
      }));
      setWithdrawlReportData(
        response?.data?.statusCode === 200 ? finalres : []
      );
      setShowLoader(false);
    } else if (response?.data?.statusCode === 304) {
      setIsAdvance(true);
      setWithdrawlReportData([]);
      setSuccessAmount("");
      setShowLoader(false);
    } else {
      setSuccessAmount("");
      setWithdrawlReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};
export const USE_LIVE_WITHDRAW_MORE = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,

  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setWithdrawlReportData,
  EditWithdrawl,
  setEditItem,
  setUtrNum,
  setTxnDate,
  setIswithdrawlmanual,
  handleDeleteRecord
  // setShowLoader
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? LIVE_WITHDRAWL_MULTIPLE_FILTER_API
        : WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    if (response?.data?.statusCode === 304) {
      setWithdrawlReportData([]);
      // setShowLoader(false);
      return;
    }

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid,
      actualAmount: item.actualAmount,
      amount: item.amount,
      serviceCharge: item.serviceCharge,
      gstCharge: item.gstCharge,
      utr: item.utr,
      transferType: item.transferType,
      virtualAccountType: item.virtualAccountType,
      status: item.status,
      fullName: item.fullName,
      emailId: item.email,
      transactionDate: item.transactionDate,
      createDate: item.createDate,
      createTime: item.createTime,
      action: (
        <div>
          <button
            className="badge badge-rounded badge-warning"
            onClick={() => {
              EditWithdrawl();
              setEditItem(item);
              setUtrNum(item.utr);
              setTxnDate(item.transactionDate);
              setIswithdrawlmanual(item.isWithdrawalManual);
            }}
          >
            Edit
          </button>

          {item.isWithdrawalManual && (
            <button
              className="badge badge-rounded badge-danger"
              onClick={() => handleDeleteRecord(item.withdrawalId)}
            >
              Delete
            </button>
          )}
        </div>
      ),
    }));
    console.log(finalRes);
    setWithdrawlReportData(finalRes ? finalRes : []);
    // setShowLoader(false);
  } catch (error) {
    console.log(error);
    // setShowLoader(false);
  }
};

// for withdrwal advance filter
export const USE_LIVE_WITHDRAW_ADVANCE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  startDate,
  endDate,
  createStartDate,
  createEndDate,

  txnDate,
  createDate,
  utr,
  status,
  mid,
  token,
  setWithdrawlReportData,
  setShowLoader,
  setIsAdvance
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      withOrWithoutMid
        ? LIVE_WITHDRAWL_MULTIPLE_FILTER_API
        : WITHDRAW_MULTIPLE_FILTER_API,
      {
        amount: amount ? parseInt(amount) : 0,
        startAmount: startAmount ? parseInt(startAmount) : 0,
        endAmount: endAmount ? parseInt(endAmount) : 0,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        createStartDate: createStartDate ? createStartDate : "",
        createEndDate: createEndDate ? createEndDate : "",
        mid: mid,
        txnDate: txnDate ? txnDate : "",
        createDate: createDate ? createDate : "",
        utr: utr ? utr : "",
        status: status ? status : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // setShowLoader(false);
    console.log(response, "jhgjgjhfhgcfgh");
    if (response?.data?.statusCode === 304) {
      setWithdrawlReportData([]);
      setShowLoader(false);
      return;
    }

    if (response?.data?.statusCode === 200) {
      const finalres = response?.data?.data?.map((item, i) => ({
        id: i,
        mid: item.mid,
        fullName: item.fullName,
        actualAmount: item.actualAmount,
        amount: item.amount,
        transferType: item.transferType,
        gstCharge: item.gstCharge,
        serviceCharge: item.serviceCharge,
        status: item.status,
        emailId: item.emailId,
        utr: item.utr,
        transactionDate: item.transactionDate,
        transferMode: item.virtualAccountType,
        virtualAccountNumber: item.virtualAccountNumber,
        ifsc: item.ifsc,
        bankName: item.bankName,
        // remark: item.remark || "NA",
        createDate: item.createDate.split(" ")[0],
        createTime: item.createTime.split(" ")[1],
      }));
      setWithdrawlReportData(response?.data?.data ? finalres : []);
      setShowLoader(false);
    } else {
      setIsAdvance(true);
      setShowLoader(false);
      setWithdrawlReportData([]);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

export const MERCHANT_PAYOUT_MULTIPLE_FILTER_API = async (
  withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  createDate,
  startDate,
  endDate,
  email,
  mobile,
  txnId,
  refId,
  rrn,
  txnStatus,
  transferType,
  upi,
  mid,
  token,
  pageM,
  sizeM,
  setApplications
) => {
  try {
    const response = await axios.post(
      !withOrWithoutMid
        ? PAYOUT_MULTIPLE_FILTER_API
        : LIVE_TRANSACTION_PAYOUT_WITHOUT_MID,
      {
        amount: amount ? amount : "",
        startAmount: startAmount ? startAmount : "",
        endAmount: endAmount ? endAmount : "",
        createDate: createDate ? createDate : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        email: email ? email : "",
        mobile: mobile ? mobile : "",
        txnId: txnId ? txnId : "",
        reference: refId ? refId : "",
        rrn: rrn ? rrn : "",
        txnStatus: txnStatus ? txnStatus : "",
        txnType: transferType,
        upi: upi ? upi : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM,
          size: sizeM,
        },
      }
    );

    console.log("responsse::", response);
    const finalres = response?.data?.data?.map((item, i) => ({
      id: i,
      mid: item.mid || "NA",
      companyName: item.companyName || "NA",
      createDate: item.createDate.split(" ")[0],
      txnRequestTime: item.txnRequestTime || "NA",
      txnResponseTime: item.txnResponseTime || "NA",
      callbackReceiveTime: item.callbackReceiveTime || "NA",
      callbackSendTime: item.callbackSendTime || "NA",
      merchantcallbackResponseTime: item.merchantcallbackResponseTime || "NA",
      callbackJsonReceived: item.callbackJsonReceived || "NA",
      callbackJSON: item.callbackJSON || "NA",
      merchantCallbackRecived: item.merchantCallbackRecived || "NA",
      merchantcallbackResponse: item.merchantcallbackResponse || "NA",
      amount: item.amount || "NA",
      accountNumber: item.accountNumber || "NA",
      ifscCode: item.ifscCode || "NA",
      email: item.email || "NA",
      mobile: item.mobile || "NA",
      reference: item.reference || "NA",
      upi: item.upi || "NA",
      createTime: item.createTime.split(" ")[1],
      rrn: item.rrn || "NA",
      txnId: item.txnId || "NA",
      transferType: item.transferType || "NA",
      paymentBy: item.paymentBy || "NA",
      txnStatus: item.txnStatus || "NA",
      payoutStatus: item.payoutStatus || "NA",
      category: item.category || "NA",
    }));

    console.log("finalres::", finalres);
    setApplications(response?.data?.data ? finalres : []);
  } catch (error) {
    console.log(error);
  }
};

export const USE_GURANTEE_MULTIPLE_FILTER_API = async (
  startGuarantyAmount,
  endGuarantyAmount,
  guarantyAmount,
  netAmount,
  startDate,
  endDate,
  transactionDate,
  utrNumber,
  mid,
  token,
  setApplications
  // setShowLoader
  // startDate,
  // endDate,
) => {
  try {
    // setShowLoader(true);
    const response = await axios.post(
      GURANTEE_MULTIPLE_FILTER_API,
      {
        startGuarantyAmount: startGuarantyAmount ? startGuarantyAmount : "",
        endGuarantyAmount: endGuarantyAmount ? endGuarantyAmount : "",
        guarantyAmount: guarantyAmount ? guarantyAmount : "",
        netAmount: netAmount ? netAmount : "",
        startDate: startDate ? startDate : "",
        enddate: endDate ? endDate : "",
        transactionDate: transactionDate ? transactionDate : "",
        utrNumber: utrNumber ? utrNumber : "",
        mid: mid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    const finalRes = response?.data?.data?.map((item, i) => ({
      id: i,
      startGuarantyAmount: item.startGuarantyAmount,
      mid: item.mid,
      endGuarantyAmount: item.endGuarantyAmount,
      guarantyAmount: item.guarantyAmount,
      netAmount: item.netAmount,
      startDate: item.startDate,
      enddate: item.enddate,
      transactionDate: item.transactionDate,
      utrNumber: item.utrNumber,
    }));
    setApplications(finalRes);
    // setShowLoader(false);
    // console.log(response?.data?.data, "gurantee - this is guarentee response");
    // setApplications(response?.data?.data ? finalRes : []);
    // console.log("this is response", finalRes)
  } catch (error) {
    console.log(error);
  }
};

export const getAllmerchantPayoutmasters = async (token) => {
  try {
    const response = await axios.get(GET_ALL_MECHANT_PAYOUT_MASTERS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const savePayoutMaster = async (token) => {
  try {
    const response = await axios.get(SAVE_MERCHANT_PAYOUT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllRoutingView = async (token) => {
  try {
    const response = await axios.get(GET_ALL_ROUTING_VIEW, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getWalletBalnceByMid = async (mid, token) => {
  try {
    const response = await axios.get(GET_WALLET_BALANCE_BY_MID + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const disabledDataPayoutMaster = async (token) => {
  try {
    const response = await axios.get(DISABLED_DATA_PAYOUT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchSIDDetails = async (token) => {
  try {
    const response = await axiosInstance.get(SID_DETAILS);

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllSubmittedPayoutRouting = async (token) => {
  try {
    const response = await axios.get(GET_ALL_SUBMITTED_PAYOUT_ROUTING, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionData = async (token) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_BOX, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID + "/" + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataTableByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID + "/" + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// UPDATE MERCHANT BANK DETAILS
export const updateMerchantBankDetails = async (payload, token) => {
  try {
    const response = await axios.put(
      `${UPDATE_MERCHANT_BANK_DETAILS}`,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// UPDATE MERCHANT BANK DETAILS
export const updateAggregatorData = async (payload, token) => {
  try {
    const response = await axios.put(`${AGGREGATOR_UPDATE}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const EnableDisableAggragator = async (enableOrDisable, id, token) => {
  try {
    const response = await axios.put(
      `${EnableDisableAggragator}/${enableOrDisable}/${id}`,
      "",
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// verify account number and ifsc code
export const accountNumberIfscCodeVerifier = async (
  ifscCode,
  accountNumber
) => {
  try {
    const res = await axios.post(ACC_NUM_IFSC_VERIFY_API, {
      ifsc: ifscCode,
      accountNumber,
    });
    return res?.data;
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

// get bank details by ifsc code
export const ifscCodeVerifier = async (ifscCode) => {
  try {
    const res = await axios.post(IFSC_VERIFY_API, { ifscCode });
    return { data: res?.data?.data, status: res?.data?.statusCode };
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

export const FirstStageApproveAndSecondNotSubmitted = async (token) => {
  try {
    const response = await axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/FirstStageApproveAndSecondNotSubmitted`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchAllOrganisationalType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ORGANISATIONAL_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllOrganisationalTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehiclecommercialTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleSettlementTypeWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleCommercialWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleTypelWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllVehicleSubTypelWithActive = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveOrganisationalType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_ORGANISATIONAL_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
// verify merchant onboard email is pre verified or not
export const verifyMerchantEmailIsVerifiedOrNot = async (email, token) => {
  try {
    const response = await axios.post(
      VERIFY_MERCHANT_ONBOARD_EMAIL,
      { email },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

// verify merchant onboard mobile is pre verified or not
export const verifyMerchantMobileIsVerifiedOrNot = async (mobile) => {
  try {
    const response = await axios.post(`${VERIFY_MERCHANT_ONBOARD_MOBILE}`, {
      mobile,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const fetchSubmittedVehicleSubType = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_SUBTYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchSubmittedVehicleType = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchSubmittedVehicleCommercial = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_VEHICLE_COMMERCIAL, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const saveVehicalCommercialType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_VEHICAL_COMMERCIAL_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const SaveAggregator = async (payload, token) => {
  try {
    const res = await axios.post(AGGREGATOR_SAVE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const SaveAggregatorUsers = async (payload, token) => {
  try {
    const res = await axios.post(AGGREGATOR_USERS_SAVE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveVehicalSettlementType = async (payload, token) => {
  try {
    const res = await axios.post(SAVE_VEHICAL_SETTLEMENT_TYPE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchAllVehicalCommercialType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICAL_COMMERCIAL_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllFunctionForApiCall = async (
  URL,
  token,
  page = 0,
  size = 20
) => {
  try {
    const res = await axios.get(URL, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page,
        size,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllFunctionForApiCalls = async (URL, token) => {
  try {
    const res = await axios.get(URL, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchAllVehicalSettlementType = async (token) => {
  try {
    const res = await axios.get(GET_ALL_VEHICAL_SETTLEMENT_TYPE, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

// export const fetchAllVehicleMasterForm = async (token) => {
//   try {
//     const res = await axios.get(GET_ALL_VEHICAL_MASTER_FORMS, {
//       headers: {
//         Authentication: "Bearer " + token,
//       },
//     });
//     // console.log(res);
//     return res?.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };
export const fetchAllVehicleMasterFormEKYC = async (token, payload) => {
  try {
    const res = await axios.post(GET_ALL_VEHICAL_MASTER_FORMS_EKYC, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllActiveAndDisableVehicleMaster = async (token) => {
  try {
    const res = await axios.get(GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllByIdUsingGet = async (token, id) => {
  try {
    const res = await axios.get(`${GET_ALL_BY_ID_USING_GET}${id}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllPhase2Submitter = async (token) => {
  try {
    const res = await axios.get(`${GET_ALL_PHASE2_SUBMITTER}`, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveVehicleMasterForm = async (payload, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.post(SAVE_VEHICLE_MASTER_FORM, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveVehicleMasterPhase2Form = async (payload, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.post(VEHICLE_MASTER_PHASE_2_SAVE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveVehicleMasterUpdateForm = async (payload, id, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.put(`${SAVE_VEHICLE_MASTER_UPDATE_FORM}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveOrganisationalTypeUpdateForm = async (payload, id, token) => {
  console.log(token);
  console.log(payload);
  try {
    const res = await axios.put(
      `${SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM}/${id}`,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getMechantForPayoutApiTest = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getMechantForPayoutOnBoarding = async (token) => {
  try {
    const response = await axios.get(
      FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const UpdateVehicleMasterPhase2Form = async (payload, token) => {
  try {
    const res = await axios.put(VEHICLE_MASTER_PHASE_2_UPDATE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMechantForEkycOnBoarding = async (token) => {
  try {
    const response = await axios.get(
      FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllSubmittedEkycRouting = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_SUBMITTED_EKYC_ROUTING, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllSubmittedEkycRoutingTools = async (token) => {
  try {
    const response = await axios.get(FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const FetchTemporaryRequest = async (token) => {
  try {
    const response = await axios.get(GET_ALL_TEMPORARY_REQUEST_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataPayout = async (
  token,
  page = 0,
  size = 20
) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page,
        size,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataPayoutByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataChargebackByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataWithdrawByMid = async (mid, token) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataReconciliationByMid = async (
  mid,
  token
) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID + mid,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataChargeback = async (
  token,
  page = 0,
  size = 20
) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page,
          size,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataWithdrawl = async (
  token,
  page = 0,
  size = 20
) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page,
        size,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const getAllDailyTransactionDataReconciliation = async (
  token,
  page = 0,
  size = 20
) => {
  try {
    const response = await axios.get(
      GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page,
          size,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getMerchantInfoByIdPayout = async (token, mid) => {
  try {
    const response = await axios.get(CALLBACK_PAYOUT_BY_MID + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const testCallbackURLPayout = async (token, callbackURL, jsonString) => {
  try {
    const response = await axios.post(
      CHECK_CALLBACK_FOR_PAYOUT,
      {
        callBackUrl: callbackURL,
        jsonString: jsonString,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const saveWithdrawlPercentage = async (payload, token) => {
  try {
    const response = await axios.put(SAVE_WITHDRAWL_PERCENTAGE, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMerchantInfoByIdPayin = async (token, mid) => {
  try {
    const response = await axios.get(CALLBACK_PAYIN_BY_MID + mid, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const testCallbackURLPayin = async (token, callBackUrl, merchantMid) => {
  try {
    const response = await axios.post(
      CHECK_CALLBACK_PAYIN,
      {
        callBackUrl: callBackUrl,
        mid: merchantMid,
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllMerchantMaster = async (token) => {
  try {
    const res = await axios.get(GET_ALL_MERCHANT_MASTER, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllScheme = async (token) => {
  try {
    const res = await axios.get(GET_ALL_SCHEME, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllBank = async (token) => {
  try {
    const res = await axios.get(GET_ALL_BANK, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllSwitchData = async (token) => {
  try {
    const res = await axios.get(GET_ALL_SWITCH_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllAggeregatorData = async (token) => {
  try {
    const res = await axios.get(GET_ALL_AGGREGATOR_DATA, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const FetchTemporaryTxnReport = async (token) => {
  try {
    const response = await axios.get(GET_TXN_REPORT, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getPhase2VehicleMasterSubmitted = async (token) => {
  try {
    const response = await axios.get(VEHICLE_MASTER_PHASE_2_SUBMITTED, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getPhase2VehicleMasterSubmittedByID = async (id, token) => {
  try {
    const response = await axios.get(
      VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID + id,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const disableVehicleMaster = async (token, regId) => {
  try {
    const res = await axios.put(`${DISABLE_VEHICLE_MASTER}/${regId}`, "", {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const enableVehicleMaster = async (token, regId) => {
  try {
    const res = await axios.put(`${ENABLE_VEHICLE_MASTER}/${regId}`, "", {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    // console.log(res);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSubmittedPayout = async (token) => {
  try {
    const response = await axios.get(SUBMITTED_PAYOUT, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
export const fetchPayoutDetail = async (token, id) => {
  try {
    const response = await axios.get(
      `${FETCH_PAYOUT_DETAILS}/${id}`,

      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );

    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const HandleApprovePayout = async (token, payload) => {
  try {
    const response = await axios.put(APPROVE_REJECT_PAYOUT, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const singlePayinReport = async (token, mid) => {
  try {
    const response = await axios.post(
      SINGLE_PAYIN_REPORT,
      { mid: mid },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllMerchant = async (token) => {
  try {
    const response = await axios.get(FETCH_WITHDRAW_AMOUNT_API, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const getAllDailyTransactionDataTable = async (
  token,
  page = 0,
  size = 20
) => {
  try {
    const response = await axios.get(GET_ALL_DAILY_TRANSACTION_DATA_TABLE, {
      headers: {
        Authentication: "Bearer " + token,
      },
      params: {
        page,
        size,
      },
    });
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const updateUserData = async (payload) => {
  try {
    const response = await axiosInstance.put(`${USER_UPDATE}`, payload);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const frmBlockVPA = async (payload, token) => {
  try {
    const res = await axios.post(`${FRM_BLOCK_USER}`, payload, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllFRMUsers = async (token) => {
  try {
    const res = await axios.get(FETCH_FRM_USERS, {
      headers: {
        Authentication: "Bearer " + token,
      },
    });

    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getVehicleForMastersForms = async (payload, token) => {
  try {
    const response = await axios.post(
      FETCH_MERCHANT_FOR_MASTERS_FORMS,
      payload,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      }
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER_API = async (
  dateSearchValue,
  endDateSearchValue,
  mid,
  sid,
  startDateSearchValue,
  token,
  setLiveBoxData
) => {
  try {
    // setShowLoader(true);
    const payload = {
      date: dateSearchValue ? dateSearchValue : "",
      endDate: endDateSearchValue ? endDateSearchValue : "",
      mid: mid,
      sid: sid,
      startDate: startDateSearchValue ? startDateSearchValue : "",
    };

    const response = await axios.post(
      GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER,
      payload,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
    );

    if (response?.data?.statusCode === 200) {
      const sortedData = response.data.data.sort((a, b) => {
        if (a.evokTxnCode === "Success") return -1;
        if (b.evokTxnCode === "Success") return 1;
        return 0;
      });

      setLiveBoxData(sortedData);
    } else {
      setLiveBoxData([]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const USE_LIVE_GUARANTEE_MULTIPLE_FILTER_API = async (
  // withOrWithoutMid,
  amount,
  startAmount,
  endAmount,
  date,
  startDate,
  endDate,
  netAmount,
  rrn,
  mid,
  token,
  pageM,
  sizeM,
  setTransactionReportData,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM
  // ViewDetailsCallbackResponse
  // setIsAdvanceFilterOpen,
) => {
  try {
    setShowLoader(true);
    const response = await axios.post(
      LIVE_GUARANTEE_MULTIPLE_FILTER_API,
      {
        endGuarantyAmount: endAmount ? endAmount : "",
        enddate: endDate ? endDate : "",
        guarantyAmount: amount ? amount : "",
        mid: mid,
        netAmount: netAmount ? netAmount : "",
        startDate: startDate ? startDate : "",
        startGuarantyAmount: startAmount ? startAmount : "",
        transactionDate: date ? date : "",
        utrNumber: rrn ? rrn : "",
      },
      {
        headers: {
          Authentication: "Bearer " + token,
        },
        params: {
          page: pageM - 1,
          size: sizeM,
        },
      }
    );
    // setShowLoader(false);

    if (response.data.statusCode === 200) {
      {
        response?.data?.duplicateData?.sumOfSuccessAmount !== null
          ? setSuccessAmount(response?.data?.duplicateData?.sumOfSuccessAmount)
          : setSuccessAmount(
              response?.data?.duplicateData?.sumOfSuccessAmountSL
            );
      }
      {
        response?.data?.duplicateData?.totaltxn !== null
          ? setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.totaltxn)
            )
          : setTxnSizeM(
              paginationCountCalc(response?.data?.duplicateData?.txnCount)
            );
      }

      const finalres = response?.data?.data?.map((item, i) => ({
        id: i,
        ...item,
      }));
      setTransactionReportData(response?.data?.data ? finalres : []);
      setShowLoader(false);
      // setIsAdvanceFilterOpen(false);
    }
    // else if(response?.data?.statusCode === 304 || 600 || 300){
    //   setTransactionReportData([{id:1}]);
    //   setSuccessAmount("")
    //   setShowLoader(false);
    // }
    // else{
    //   setSuccessAmount("")
    //   setTransactionReportData([]);
    //   setShowLoader(false)
    // }
    else {
      setSuccessAmount("");
      // setIsAdvanceFilterOpen(false);
      setTransactionReportData([]);
      setShowLoader(false);
    }
  } catch (error) {
    console.log(error);
    setShowLoader(false);
  }
};

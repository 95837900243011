import React, { useState, useEffect, useCallback } from 'react'
import { Card, Col, Row, Offcanvas, Button, Modal } from "react-bootstrap";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import DownloadCsv from '../../common/DownloadCsv';
import MidFiter from './MidFiter';
import { useNavigate } from 'react-router-dom';
import LiveWithdrawlMultipleFilter from "./LiveWithdrawlMultipleFilter"
import LiveWithdrawlAdvanceFilter from "./LiveWithdrawlAdvanceFilter"
import { getAllDailyTransactionDataWithdrawl } from "../../utils/ApiServices";
import FloatingReports from '../../common/FlotingReports';
import { GET_REPORT_API } from "../../utils/constant";
import useApiServices from '../../utils/useApiServices';
import MerchantIcon from '../../common/MerchantIcon';
import IconWrapper from '../../common/IconWrapper/IconWrapper';
import { Box } from '@mui/material';
import Shimmer from '../../common/Shimmer/Shimmer';
import Pagination from "@mui/material/Pagination";
import Filters from './Filters';
import { debounce } from 'lodash';
import { paginationCountCalc } from '../../utils/helper';
import NewDowloadCSV from './NewDownloadCSV';
import DataNotFound from "../../common/DataNotFound/DataNotFound";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const Withdraw = ({ dropDownData, type, toggleRightBar }) => {
  console.log(type)
  const { apiCallFnforPostForQueryParams } = useApiServices();
  console.log("drop down is", dropDownData);
  const [data, setData] = useState([]);
  const [dataM, setDataM] = useState([]);
  const token = localStorage.getItem("token");
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState(false);
  const [show2, setShow2] = useState(false);
  const [condition, setCondition] = useState("1 day");
  const [mid, setMid] = useState("");
  const navigate = useNavigate();
  const [changeCount, setChangeCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [successAmout, setSuccessAmount] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [size, setSize] = useState(100);
  const [txnSize, setTxnSize] = useState(0);
  const [txnSizeM, setTxnSizeM] = useState(0);
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [isAdvance, setIsAdvance] = useState(false);

  const getDailyTransactionData = async () => {
   setShowLoader(true)
    await apiCallFnforPostForQueryParams(GET_REPORT_API, {
      mid: mid,
      reportType: "Withdraw",
      timeFrame: condition,
    },
      {
        page: page-1,
        size,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
          setTxnSize( paginationCountCalc(response?.duplicateData?.txnCount || response?.duplicateData?.totaltxn));
          setSizeOfTransactions(response?.duplicateData?.txnCount)
          const finalres = response?.data?.map((item, i) => ({
            id: i,
            mid: item.mid,
            fullName: item.fullName,
            actualAmount: item.actualAmount,
            amount: item.amount ,
            transferType: item.transferType,
            gstCharge:item.gstCharge,
            serviceCharge:item.serviceCharge,
            status: item.status,
            emailId: item.emailId ,
            utr: item.utr,
            transactionDate: item.transactionDate,
            transferMode: item.virtualAccountType ,
            virtualAccountNumber: item.virtualAccountNumber,
            ifsc: item.ifsc,
            bankName: item.bankName, 
            // remark: item.remark || "NA",
            createDate: item.createDate.split(" ")[0],
            createTime: item.createTime
          }));
          setData(finalres);
          setDataM([]);
          setCount(response.size);
          setShowLoader(false)


        } else {
          setData([]);
          setShowLoader(false);
          setSuccessAmount("");
        
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  
  const columns = [
    { field: "mid", headerName: "Merchant ID", minWidth: 170, },
    { field: "fullName", headerName: "Company Name", minWidth: 230, },
    { field: "actualAmount", headerName: "Requested-Amount", minWidth: 150, },
    { field: "amount", headerName: "Amount", minWidth: 150, },
    { field: "serviceCharge", headerName: "Service Charge", minWidth: 150 },
    { field: "gstCharge", headerName: "GST Charge", minWidth: 150 },
    { field: "transferType", headerName: "Transfer Type", minWidth: 150 },

    { field: "status", headerName: "Status", minWidth: 150 },
    { field: "emailId", headerName: "e-Mail Id", minWidth: 230 },
    { field: "utr", headerName: "UTR Number", minWidth: 150 },
    { field: "transactionDate", headerName: "Transaction Date", minWidth: 150 },
    { field: "transferMode", headerName: "TransferMode", minWidth: 150 },
    { field: "virtualAccountNumber", headerName: "Virtual Account Number", minWidth: 190 },
    { field: "ifsc", headerName: "IFSC Code", minWidth: 150 },
    { field: "bankName", headerName: "Bank Name", minWidth: 150 },
    // { field: "remark", headerName: "Remark",  minWidth: 150},
    { field: "createDate", headerName: "Created Date", minWidth: 150 },
    { field: "createTime", headerName: "Created Time", minWidth: 150 },
  ];

  // useEffect(() => {
  //   getDailyTransactionData();
  // }, [reset, condition, setCondition, mid, setMid, changeCount, setChangeCount, page]);

  const debouncedMiscellenious =useCallback((
    debounce(()=>{
      getDailyTransactionData();
    },0)
  ), [reset, condition,page, mid]
  )

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);



  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleReset = () => {
    setReset(!reset);
    setMid("");
    setPageM(1);
    setPage(1);
    setDataM([]);
    setCondition("1 day");
    // getDailyTransactionData();
    setIsAdvance(false);
    setIsAdvanceFilterOpen(false);
  };
  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };
  const handleClose = () => setShow(false);


  function CustomToolbar() {
   
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div className="d-flex justify-content-center gap-2">
            <GridToolbarExport className="badge badge-sm badge-primary text-white fw-bold" />
            <div
              onClick={() => setShow(true)}
              style={{ color: "#F6FAFD", cursor:"pointer" ,fontSize:"13px"}}
              className="d-flex align-items-center gap-1"
            >
              {" "}
              <span
                style={{ color: "#3C76D2", fontWeight: "900" }}
                className="mb-1"
              >
                {/* <i class="bi bi-download"></i> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#ff6e33"
                  class="bi bi-download fw-bold"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg> */}
              </span>

              {/* <span style={{ color: "#ff6e33", fontWeight: "500" }}>EXPORT {">"} 100</span> */}

            </div>

            
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start"><GridToolbarQuickFilter /></div>
          </div>
        </div>
      </GridToolbarContainer>
    );

    
  }


  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeM = (event, value) => {
    setPageM(value);
  };

  return (
    <div className="container-fluid">
      <MerchantIcon setMid={setMid}  reset={reset}/>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <section className="lincpay-dashboard-sec">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className='d-flex align-items-center'>
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>

                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"reset"}>
                        <i className="fa fa-refresh" onClick={HandleReset}></i>

                      </IconWrapper>
                      <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleAdvanceFilter}></i>
                      </IconWrapper>
                      {/* <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleMultipleFilter}></i>
                      </IconWrapper> */}
                    </div>
                  </div>
                  <div className="report-grid-view">
                    <div className="material-table-records mb-3" style={{height:"500px"}}>

                      {showLoader ? (
                        <Shimmer type= "table" />
                      )
                      : (isAdvanceFilterOpen && isAdvance && dataM.length === 0) ? (
                        <div className="no-data-found">No data found for the selected filters.</div>
                      )
                      : (!isAdvanceFilterOpen && data.length === 0 && dataM.length === 0) ? (
                        // <div className="no-data-found">No data found.</div>
                        <DataNotFound />
                      ) : !dataM?.length ? (
                        <>
                          <DataGrid
                            rows={data}
                            columns={columns}
                            slots={{
                              toolbar: CustomToolbar
                            }}
                            // className="mb-1 text-dark fw-bold"
                            hideFooterPagination={true}
                            // className="mb-1 text-dark fw-bold"
                            sx={{
                              fontSize: `${zoomCount}px`,
                              "& .css-1rtad1": {
                                display: "block",
                              },  // Adjust the font size as needed
                            }}
                          />
                          <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                            <Pagination
                              count={txnSize}
                              page={page}
                              onChange={handleChange}
                            />
                          </div>

                        </>

                      )
                      : (
                        <>
                          <DataGrid
                            rows={dataM}
                            columns={columns}
                            slots={{
                              toolbar: CustomToolbar,
                            }}
                            hideFooterPagination={true}
                            // className="mb-1 text-dark fw-bold"
                            sx={{
                              fontSize: `${zoomCount}px`,
                              "& .css-1rtad1": {
                                display: "block",
                              },  // Adjust the font size as needed
                            }}
                          />
                          

                            <Pagination
                              count={txnSizeM}
                              page={pageM}
                              onChange={handleChangeM}
                            />
                          
                        </>

                      ) 
                      // : (
                      //   <h2
                      //     style={{
                      //       display: "flex",
                      //       flexDirection: "row",
                      //       justifyContent: "center",
                      //       alignItems: "center",
                      //     }}
                      //   >
                      //     No Data Found
                      //   </h2>

                      // )
                      }
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                 
                    </div>
                  </div>
                </section>
                {/* <MidFiter reportType={reportType} setShow2={setShow2} show2={show2} setLiveData={setData} /> */}
                {isAdvanceFilterOpen && <Filters
                setShowLoader={setShowLoader}
                setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                setWithdrawlReportData={setDataM}
                    setShowMultipleFilter={setIsMultipleFilterOpen}
                    dropDownData={dropDownData}
                    type={type}
                    mid={mid}
                    setSizeM={setSizeM}
                    sizeM={sizeM}
                    pageM={pageM}
                    setSuccessAmount={setSuccessAmount}
                    setTxnSizeM={setTxnSizeM}
                    setIsAdvance={setIsAdvance}
                />}
                {/* {isAdvanceFilterOpen ? (
                  <LiveWithdrawlAdvanceFilter
                    setTransactionReportData={setData}
                    setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  />
                ) : isMultipleFilterOpen ? (
                  <LiveWithdrawlMultipleFilter
                    setWithdrawlReportData={setData}
                    setShowMultipleFilter={setIsMultipleFilterOpen}
                    dropDownData={dropDownData}
                  />
                ) : (
                  ""
                )} */}
                {/* </section> */}

                {/* </Offcanvas.Body>
      </Offcanvas> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingReports
        type="reports"
        setCondition={setCondition}
        mid={mid}
        // reportType={type}
        setData={setData}
        reset={reset}
      />
{show === true ? <NewDowloadCSV type="withdraw" show={show} setShow={setShow} /> : null}
{/* <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Withdraw Report</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" className="fs-5 btn btn-md" onClick={handleClose}>
          Close
        </Button>
        <DownloadCsv
                    DownLoadData={data}
                    Name={`Live Withdraw Report`}
                  />  
      </Modal.Footer>
    </Modal> */}

    </div>

  )
}

export default Withdraw
import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Row, Offcanvas, Button, Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { getAllDailyTransactionDataChargeback } from "../../utils/ApiServices";
import MidFiter from "./MidFiter";
import DownloadCsv from "../../common/DownloadCsv";
import LiveChargebackAdvanceFilter from "./LiveChargebackAdvanceFilter";
import LiveChargebackMultipleFilter from "./LiveChargebackMultipleFilter";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import FloatingReports from "../../common/FlotingReports";
import useApiServices from "../../utils/useApiServices";
import Pagination from "@mui/material/Pagination";
import { GET_REPORT_API } from "../../utils/constant";
import MerchantIcon from "../../common/MerchantIcon";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { Box } from "@mui/material";
import swal from "sweetalert";
import Shimmer from "../../common/Shimmer/Shimmer";
import Filters from "./Filters";
import { paginationCountCalc } from "../../utils/helper";
import NewDowloadCSV from "./NewDownloadCSV";
import DataNotFound from "../../common/DataNotFound/DataNotFound";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const Chargeback = ({ dropDownData, reportType, type, toggleRightBar }) => {

  const { apiCallFnforPostForQueryParams } = useApiServices();
  const [data, setData] = useState([]);
  const [dataM, setDataM] = useState([]);

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [show2, setShow2] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [10, 20, 30, 40, 50];
  const [reset, setReset] = useState(false);
  const [condition, setCondition] = useState("1 day");
  const [mid, setMid] = useState("");
  const navigate = useNavigate();
  const [changeCount, setChangeCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [successAmout, setSuccessAmount] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [txnSize,setTxnSize] = useState(0);
  const [txnSizeM,setTxnSizeM] = useState(0);
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [isAdvance, setIsAdvance] = useState(false);

  const getDailyTransactionData = async () => {
     setShowLoader(true)
    await apiCallFnforPostForQueryParams(GET_REPORT_API, {
      mid: mid,
      reportType: "Chargeback",
      timeFrame: condition,
    },
      {
        page: page-1,
        size,
      }
    )
      .then((response) => {
        console.log('response...................',response);
        if (response.statusCode == 200) {
          setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
          setTxnSize( paginationCountCalc(response?.duplicateData?.txnCount || response?.duplicateData?.totaltxn));
          setSizeOfTransactions(response?.duplicateData?.txnCount);
          const finalRes = response?.data.map((item, i) => ({
            id: i,
            mid: item.mid,
            fullName: item.fullName,
            chargeBackAdjDate: item.chargeBackAdjDate,
            createDate: item.createDate,
            chargeBackAmount: item.chargeBackAmount,
            rrn: item.rrn,
            compensationPenalty: item.compensationPenalty,
            txnDate: item.transactionDate ? item.transactionDate.split(' ')[0] : "",
            txnTime: item.transactionDate ? item.transactionDate.split(' ')[1] : "",
            ifscCode: item.ifscCode,
            payerVpa: item.payerVpa,
            payeeBank: item.payeeBank,
            mobileNumber: item.mobileNumber,
            emailId: item.emailId,
            bankLocation: item.bankLocation,
            payeeLocation: item.payeeLocation,

          }));

          console.log('finalres',finalRes);
          setData(finalRes);
          setDataM([]);
          setShowLoader(false)
        } else {
          setData([]);
          setShowLoader(false)
          setSuccessAmount("");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };
console.log(data)
  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData();
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      getDailyTransactionData();
    }
  };
console.log(txnSizeM);

  const columns = [
    // { headerName: "ID", field: "id", minWidth: 100 },
    { headerName: "Merchant ID", field: "mid", minWidth: 170 },
    { headerName: "Company Name", field: "fullName", minWidth: 230 },
    { headerName: "ChargeBack Adjustment Date", field: "chargeBackAdjDate", minWidth: 190 },
    { headerName: "Chargeback-Upload Date", field: "createDate", minWidth: 150 },
    { headerName: "ChargeBack Amount", field: "chargeBackAmount", minWidth: 170 },
    { headerName: "UTR Number", field: "rrn", minWidth: 150 },
    { headerName: "Compensation Penalty", field: "compensationPenalty", minWidth: 180 },
    { headerName: "Transaction Date", field: "txnDate", minWidth: 170 },
    // { headerName: "Transaction Time", field: "txnTime", minWidth: 100 },
    { headerName: "IFSC Code", field: "ifscCode", minWidth: 150 },
    { headerName: "Payer VPA", field: "payerVpa", minWidth: 230 },
    { headerName: "Payee Bank", field: "payeeBank", minWidth: 100 },
    { headerName: "Mobile Number", field: "mobileNumber", minWidth: 150 },
    { headerName: "Email ID", field: "emailId", minWidth: 230 },
    { headerName: "Bank Location", field: "bankLocation", minWidth: 120 },
    { headerName: "Payee Location", field: "payeeLocation", minWidth: 140 },
  ];

  // const mappedData = data?.map((item) => {
  //   const mappedItem = {};
  //   columns.forEach((column) => {
  //     if (
  //       column.field === "createDate" ||
  //       column.field === "chargeBackAdjDate" ||
  //       column.field === "transactionDate"
  //     ) {
  //       const dateValue = item[column.field]
  //         ? item[column.field].split(" ")[0]
  //         : "";
  //       mappedItem[column.field] = dateValue;
  //     } else if (column.field === "isCallbackSand") {
  //       const lkjhValue = item[column.field] === true ? "Yes" : "No";
  //       mappedItem[column.field] = lkjhValue;
  //     } else if (column.field === "callBackRecive") {
  //       const iuyfddfgh = item[column.field] === "yes" ? "Yes" : "No";
  //       mappedItem[column.field] = iuyfddfgh;
  //     } else {
  //       mappedItem[column.field] = item[column.field];
  //     }
  //   });
  //   return mappedItem;
  // });



  const handleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(!showAdvanceFilter);
  };
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleReset = () => {
    setReset(!reset);
    setMid("");
    setCondition("1 day");
    setPage(1);
    setPageM(1);
    setDataM([]);
    // getDailyTransactionData();
    setIsAdvance(false);
    setShowAdvanceFilter(false);
  };


  // [ currentPage,
  //   condition,
  //   mid,
  //   page,
  //   reset,
  //   entriesPerPage]

  const debouncedMiscellenious =useCallback((
    debounce(()=>{
      getDailyTransactionData();
    },0)
  ), [reset, currentPage, entriesPerPage, condition,page, mid]
  )

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);


  // useEffect(() => {
  //   getDailyTransactionData();
  // }, [reset, currentPage, entriesPerPage, condition,page, mid]);

  // useEffect(() => {
  //   getDailyTransactionData()
  // }, [mid, setMid, changeCount, setChangeCount,page])

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };

  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

  // function CustomToolbar() {

  //   const openModal = () => {
  //     swal(
  //       "Maximum Transactions Limit Exceeds, Plz Download From Archieve Reports.",
  //       {
  //         buttons: {
  //           cancel: "Cancel",
  //           confirm: {
  //             text: "OK",
  //             value: "ok",
  //           },
  //         },
  //       }
  //     ).then((value) => {
  //       if (value === "ok") {
  //         swal.close();
  //         // 1 is stand for archieve report
  //         // navigate("/Tools/1");
  //       }
  //     });
  //   };



  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarColumnsButton />
  //       <GridToolbarFilterButton />
  //       <GridToolbarDensitySelector
  //         slotProps={{ tooltip: { title: "Change density" } }}
  //       />
  //       <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />


  //       <Box sx={{ flexGrow: 1 }} />
  //       <div class="row">
  //         <div class="col text-end">
  //           <span class="m-b-0 me-2 fs-18 text-success">
  //             Success amount :
  //             <span className="mx-2">
  //               {successAmout}
  //             </span>
  //           </span>
  //         </div>
  //       </div>
  //     </GridToolbarContainer>
  //   );


  // }
  const handleClose = () => setShow(false);

  function CustomToolbar() {
   
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div className="d-flex justify-content-center gap-2">
            <GridToolbarExport className="badge badge-sm badge-primary text-white fw-bold" />
            <div
              onClick={() => setShow(true)}
              style={{ color: "#F6FAFD", cursor:"pointer" ,fontSize:"13px"}}
              className="d-flex align-items-center gap-1"
            >
              {" "}
              <span
                style={{ color: "#3C76D2", fontWeight: "900" }}
                className="mb-1"
              >
                {/* <i class="bi bi-download"></i> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#ff6e33"
                  class="bi bi-download fw-bold"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg> */}
              </span>

              {/* <span style={{ color: "#ff6e33", fontWeight: "500" }}>EXPORT {">"} 100</span> */}

            </div>

            
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start"><GridToolbarQuickFilter /></div>
          </div>
        </div>
      </GridToolbarContainer>
    );

    
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeM = (event, value) => {
    setPageM(value);
  };

  return (
    <div className="container-fluid">
      <MerchantIcon setMid={setMid}  reset={reset}/>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">

                <section className="lincpay-dashboard-sec">
                  {/* <div className="d-flex justify-content-end"> */}
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className='d-flex align-items-center'>
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>
                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"reset"}>
                        <i className="fa fa-refresh" onClick={HandleReset}></i>

                      </IconWrapper>
                      <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleAdvanceFilter}></i>
                      </IconWrapper>
                      {/* <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleMultipleFilter}></i>
                      </IconWrapper> */}
                    </div>
                  </div>

                  <div className="dasboard-view-count-boxes">
                    <Card.Body>
                      <div className="report-grid-view">
                        <div className="material-table-records mb-3" style={{height:"500px"}}>
                          {showLoader ? (
                            <Shimmer type="table" />
                          ): (showAdvanceFilter && isAdvance && dataM.length === 0) ? (
                            <div className="no-data-found">No data found for the selected filters.</div>
                          )
                          : (!showAdvanceFilter && data.length === 0 && dataM.length === 0) ? (
                            // <div className="no-data-found">No data found.</div>
                            <DataNotFound />
                          )
                          :  !dataM?.length ?  (
                            <>
                              <DataGrid
                                rows={data}
                                columns={columns}

                                slots={{
                                  toolbar: CustomToolbar
                                }}
                                // className="mb-1 text-dark fw-bold"
                                hideFooterPagination={true}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  }, 
                                }}
                              />
                              <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                                <Pagination
                                  count={txnSize}
                                  page={page}
                                  onChange={handleChange}
                                />
                              </div>
                            </>

                          ):  (
                            <>
                              <DataGrid
                                rows={dataM}
                                columns={columns}

                                slots={{
                                  toolbar: CustomToolbar
                                }}
                                // className="mb-1 text-dark fw-bold"
                                hideFooterPagination={true}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  }, 
                                }}
                              />
                              <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                                <Pagination
                                  count={txnSizeM}
                                  page={pageM}
                                  onChange={handleChangeM}
                                />
                              </div>
                            </>

                          ) }
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <DownloadCsv
                    DownLoadData={data}
                    Name={`Live Chargeback Report`}
                  />   */}
                          {/* <div className="pagination-buttons d-flex justify-content-end gap-2">
                            <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                              // onClick={handlePreviousPage}
                              disabled={changeCount === 0}
                              onClick={() => setChangeCount(changeCount - 1)}
                            >
                              Previous
                            </button>
                            <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                              //  onClick={handleNextPage}
                              onClick={() => setChangeCount(changeCount + 1)}
                            >Next
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </Card.Body>
                    {/* <MidFiter
                reportType={reportType}
                setShow2={setShow2}
                show2={show2}
                setLiveData={setData}
              /> */}
                      {showAdvanceFilter ? <Filters
                       setChargebackReportData={setDataM}
                        setIsAdvanceFilterOpen={setShowAdvanceFilter}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        dropDownData={dropDownData}
                        type={type}
                        mid={mid}
                        setSizeM={setSizeM}
                        sizeM={sizeM}
                        pageM={pageM}
                        setShowLoader={setShowLoader}
                        setSuccessAmount={setSuccessAmount}
                        setTxnSizeM={setTxnSizeM}
                        setIsAdvance={setIsAdvance}
                      />
                    :
                     null }
                    {/* {showAdvanceFilter ? (
                      <LiveChargebackAdvanceFilter
                        //   setShowLoader={setShowLoader}
                        setChargebackReportData={setData}
                        setIsAdvanceFilterOpen={setShowAdvanceFilter}
                      />
                    ) : isMultipleFilterOpen ? (
                      <LiveChargebackMultipleFilter
                        // setShowLoader={setShowLoader}
                        setChargebackReportData={setData}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        dropDownData={dropDownData}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* </Container> */}
                </section>
                {/* </Offcanvas.Body>
      </Offcanvas> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingReports
        type="reports"
        setCondition={setCondition}
        mid={mid}
        // reportType={type}
        setData={setData}
        reset={reset}
      />


      {/* Download CSv */}
      {show === true ? <NewDowloadCSV type="chargeback" show={show} setShow={setShow} /> : null}
       {/* <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Chargeback Report</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" className="fs-5 btn btn-md" onClick={handleClose}>
          Close
        </Button>
        <DownloadCsv
                    DownLoadData={data}
                    Name={`Live Chargeback Report`}
                  />  
      </Modal.Footer>
    </Modal> */}

    </div>
  );
};

export default Chargeback;

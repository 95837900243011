import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../store/slices/userSlice";
import Card from "./Card";
import MiddleCard from "./MiddleCard";
import PreviousTxns from "./PreviousTxns";
import VirtualAssistant from "./VirtualAssistant";
import TotalTransaction from "./TotalTransaction";
import InvoiceRemaining from "./InvoiceRemaining";
import InvoiceSent from "./InvoiceSent";
import InvoiceCompleted from "./InvoiceCompleted";
import PieCharts from "./PieChart";
import FloatingIcon from "../common/FloatingIcon";
import { API_DASHBOARD_WITH_CONDITION,FETCH_ALL_PAYIN_SERVICES, REPORT_ERROR_CODES,GET_DASHBOARD_CURRENT_DATA} from "../utils/constant";
import SimpleBarChart from "./BarChart";
import useApiServices from "../utils/useApiServices";
import MerchantIcon from "../common/MerchantIcon";
import { serviceColors,errorCodes } from "../utils/ConstantKeys";
import icon1 from "../Assets/images/cardicons/bank3.jpeg";
import icon2 from "../Assets/images/cardicons/bank50.jpeg";
import icon3 from "../Assets/images/cardicons/credit50.jpeg";
import icon4 from "../Assets/images/cardicons/creditCard.jpeg";


const DashBoard = () => {
  const [boxData, setBoxData] = useState([]);
  const [combinedApiRes, setCombinedApiRes] = useState([]);
  const [payinServices, setPayinServices] = useState([]);
  const [totalTransactionData, setTotalTransactionData] = useState([]);
  const [availablePayoutBalance, setAvailablePayoutBalance] = useState("");
  const [servicesPieChartData, setServicesPieChartData] = useState([]);
  const [errorCodelist, setErrorCodeList] = useState([]);
  const [data,setData] = useState({});
  const [merchantData, setMerchantData] = useState([]);
  const [mid,setMid] = useState("");
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
   const [condition, setCondition] = useState("this year");
   const [selectedCompany,setSelectedCompany] = useState("");
   const [currentAvailable,setCurrentAvailabe] = useState("");
   const [showLoader,setShowLoader] = useState(true);
   
   

  const dispatch = useDispatch();
  const haandleClisck = () => {
    dispatch(setIsAuthenticated(false));
  };

  useEffect(() => {
    getDataCombined();
    getErrorCodeData();
  }, [condition,mid,setMid]);

  useEffect(() => {
    DistributeData(combinedApiRes)
  }, [combinedApiRes]);

  const DistributeData = (combinedApiRes) => {
    const data = {
       mid:combinedApiRes.mid ,
      totalCount: combinedApiRes.totalCount,
      totalAmt: combinedApiRes.totalAmt,
      successCount: combinedApiRes.successCount,
      failureCount: combinedApiRes.failureCount,
      declined: combinedApiRes.declined,
      payoutTotalCount: combinedApiRes.payoutTotalCount,
      payoutSuccessCount: combinedApiRes.payoutSuccessCount,
      payoutFailureCount: combinedApiRes.payoutFailureCount,
      payoutPendingCount: combinedApiRes.payoutPendingCount,
      filterTxnList: combinedApiRes.filterTxnList

   };

    // const data2 = {
    //   settlmentAmout: combinedApiRes.settlmentAmout,
    //   depositAmount: combinedApiRes.depositAmount,
    //   totalCommisionEarnedAmount: combinedApiRes.totalCommisionEarnedAmount,
    //   chragebackAmount: combinedApiRes.chragebackAmount,
    //   holdAmount: combinedApiRes.holdAmount,
    //   withdrawalAmount: combinedApiRes.withdrawalAmount,
    //   payAmount: combinedApiRes.payAmount,
    //   totalwalletBalance: combinedApiRes.totalwalletBalance,
    //   securityDepositAmount: combinedApiRes.securityDepositAmount,
    //   guranteeAmount: combinedApiRes.dropCreditAmount,
    //   dropCreditAmount: combinedApiRes.dropCreditAmount,
    //   totalAmt: combinedApiRes.totalAmt,
    //   withDrawToAccount: combinedApiRes.withDrawToAccount,
    //   withDrawTopayout: combinedApiRes.withDrawTopayout,
    //   settlementCharge:combinedApiRes.settlementCharge,
    //   directDeposite:combinedApiRes.directDeposite
    //    };

    const data2 = {
      totalAmt: combinedApiRes.totalAmt,
      settlmentAmout: combinedApiRes?.settelmentAmountNetAMtToWallet,
      settlementCharge: combinedApiRes?.settlementCharge,
      settlmentGstCharge: combinedApiRes?.settlmentGstCharge,
      depositAmount: combinedApiRes?.securityDepositAmount,
      depositeToWallet: combinedApiRes?.depositeToWallet,
      depositeToPayout: combinedApiRes?.depositeToPayout,
      totalCommisionEarnedAmount: combinedApiRes?.totalCommisionEarnedAmount,
      chragebackAmount: combinedApiRes?.chragebackAmount,
      holdAmount: combinedApiRes?.holdAmount,
      withdrawalAmount: combinedApiRes?.withdrawalAmount,
      withDrawTopayout: combinedApiRes?.withDrawTopayout,
      withDrawToAccount: combinedApiRes?.withDrawToAccount,
      withdrawnTocredit: combinedApiRes?.dropCreditAmount,
      payoutGstCharge: combinedApiRes?.payoutGstCharge,
      payoutaccountCharge: combinedApiRes?.payoutaccountCharge,
      payoutServiceCharge: combinedApiRes?.payoutServiceCharge,
      payAmount: combinedApiRes?.payAmount,
      totalwalletBalance: combinedApiRes?.totalwalletBalance,
       
      securityDepositAmount: combinedApiRes?.securityDepositAmount,
      guranteeAmount: combinedApiRes?.guranteeAmount,
      dropCreditAmount: combinedApiRes?.dropCreditAmount,
    };

    const data3 = {
      upiAmount: combinedApiRes.map.upiAmount,
      wallet: combinedApiRes.map.wallet,
      impsAmount: combinedApiRes.map.impsAmount,
      offlineMerchantCount:combinedApiRes?.offlineMerchantCount,
      onlineMerchantCount:combinedApiRes?.onlineMerchantCount,
    };

    setBoxData(data2);
    setTotalTransactionData(data);
    setAvailablePayoutBalance(data3);
  };
console.log(availablePayoutBalance)
  const { privilagesData } = useSelector((state) => state.privilages);

  const getCurrentAvailableData = async () => {
  
    try {
      setShowLoader(true);
      const response = await apiCallFnforGet(GET_DASHBOARD_CURRENT_DATA + (mid || "na"));
      if (response.statusCode === 200) {
        setCurrentAvailabe(response);
        setShowLoader(false);
      } else {
        setCurrentAvailabe([]);
        setShowLoader(false);
      }
    } catch (error) {
      setBoxData(true);
      setShowLoader(false);
    }
    
  };
  
  useEffect(()=>{
    getCurrentAvailableData()
  },[mid,setMid])

 const getDataCombined = async () => {
    const paylaod = {
      mid: mid,
      timeFrame: condition,
    };
    try {
      setShowLoader(true);
      setCombinedApiRes([]); 
      const response = await apiCallFnforPost(
        API_DASHBOARD_WITH_CONDITION,
        paylaod
      );
      if (response.statusCode === 200) {
        setCombinedApiRes(response?.responseData);
        setShowLoader(false); 
      } else {
        setCombinedApiRes([]);
        setShowLoader(false); 
      }
    } catch (error) {
      setBoxData(true);
      setShowLoader(false); 
      
    }
    
  };

  const getDataPayinServices = async () => {
    const paylaod = {
      mid:mid,
      timeFrame:condition
    }
    try {
      setShowLoader(true);
       setPayinServices([]);
      const response = await apiCallFnforPost(FETCH_ALL_PAYIN_SERVICES,paylaod);
      if (response.statusCode === 200) {
        setPayinServices(response.responseData);
        setServicesPieChartData(
          response?.responseData?.map((item, i) => {
            return {
              id: i + 1,
              label: item.paymentService,
              value: item.percentage,
              color:serviceColors[item?.paymentService]
            };
          })
        );
        setShowLoader(false);
      } else {
        setPayinServices([]);
        setServicesPieChartData([])
        setShowLoader(false);
      }
    } catch (error) {
      setPayinServices([]);
      setServicesPieChartData([])
      setShowLoader(false);
    }
  };  

  const getErrorCodeData = async () => {
    const payload = {
      mid: mid,
      timeFrame: condition,
    };
    setShowLoader(true); // Start loader when fetching data
    try {
      const response = await apiCallFnforPost(REPORT_ERROR_CODES, payload);
      if (response?.statusCode === 200) {
        const sortedData = response.data.sort((a, b) => {
          if (a.evokTxnCode === "Success") return -1;
          if (b.evokTxnCode === "Success") return 1;
          return 0;
        });
  
        setErrorCodeList(
          sortedData.map((item, i) => {
            return {
              id: i + 1,
              label: item.evokTxnCode,
              value: item.count,
              color: errorCodes[item.evokTxnCode]
            };
          })
        );
      } else {
        setErrorCodeList([]);
      }
    } catch (error) {
      setErrorCodeList([]);
    } finally {
      setShowLoader(false); // Stop loader after data is fetched
    }
  };
  
  
 
 useEffect(() => {
    // getData();
    // getTotalTransectionData();
    // getDataPayinServices();
    // fetchBalance(mid);
    getDataPayinServices();
  }, [mid,condition]);

  return (
    <div class="content-body">
    <FloatingIcon setCondition={setCondition} merchantData={merchantData}/>
    <MerchantIcon   setMid={setMid} />
    <div class="container-fluid">
      <div class="row invoice-card-row">
        <Card
          name="Total Payin"
          amount={totalTransactionData?.totalAmt}
          color={"bg-primary"}
          icon={icon1}
          showLoader={showLoader}
        />
        <Card
          name="Payout Balance"
          imps={currentAvailable?.responseData2?.impsAmount}
          upiAmount={currentAvailable?.responseData2?.upiAmount}
          amount={
            parseFloat(currentAvailable?.responseData2?.impsAmount ?? 0) + 
            parseFloat(currentAvailable?.responseData2?.upiAmount ?? 0)
          }
          color={"bg-secondary"}
          icon={icon2}
          showLoader={showLoader}
        />
        <Card
          name="Credit Balance"
          amount={currentAvailable?.responseData?.dropCreditAmount}
          color={"bg-info"}
          icon={icon3}
          showLoader={showLoader}
        />
        <Card
          name="Total Chargeback"
          amount={boxData?.chragebackAmount}
          color={"bg-warning"}
          icon={icon4}
          showLoader={showLoader}
        />
      </div>
      <div class="row">
        <MiddleCard
          data={currentAvailable?.responseData2?.wallet}
          payinServices={payinServices}
          availablePayoutBalance={availablePayoutBalance}
        />
        <PieCharts data={servicesPieChartData} type={"services"} />
        <PreviousTxns data={boxData} payout={currentAvailable?.responseData2?.impsAmount}
          dataW={currentAvailable?.responseData2?.wallet}
        
        />
        <div class="col-xl-6 col-xxl-6">
          <div class="row">
            <VirtualAssistant />
            <TotalTransaction
            title="Total Transactions"
            data={totalTransactionData.totalCount} />
            <TotalTransaction
            title="Total Success Txn"
            data={totalTransactionData.successCount} />
            <TotalTransaction
            title="Total Failed Txn"
            data={totalTransactionData.failureCount} />
            <TotalTransaction
            title="Total Declined Txn" 
            data={totalTransactionData.declined} />
            <TotalTransaction
             title="Payout Total Transactions"
            data={totalTransactionData.payoutTotalCount} />
            <TotalTransaction
              title="Payout Total Success Txn"
             data={totalTransactionData.payoutSuccessCount} />
            <TotalTransaction
            title="Payout Total Failed Txn"
            data={totalTransactionData.payoutFailureCount} />
            <TotalTransaction
             title="Payout Pending Txn"
            data={totalTransactionData.payoutPendingCount} />

          </div>
        </div>
      </div>
      <div className="row" >
        {/* <Graphs />  */}
        {/* <LineCharts condition={condition}/> */}
        <SimpleBarChart condition={condition} mid={mid}/>
        <PieCharts data={errorCodelist} showLoader={showLoader} />
      </div>
    </div>
  </div>
  );
};

export default DashBoard;
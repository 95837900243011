import React, { useState, useCallback, useRef, useEffect } from "react";
import { Table } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { Offcanvas } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import {
  DISABLE_PAYOUT_RULE,
  DISABLE_RULE,
  GET_AMOUNT_RULES,
  GET_PAYOUT_AMOUNT_RULES,
  UPDATE_MID_RULES,
  UPDATE_MID_PAYOUT_RULES,
} from "../../utils/constant";
import swal from "sweetalert";

const ShuffleRules = ({ showRule, setShowRule, mid, viewOnly, isPayout }) => {
  const type = "DraggableBodyRow";
  const [data, setData] = useState([]);

  // const [data, setData] = useState([
  //     {
  //       prority: 1,
  //       domain: "example.com",
  //       mid: "MID123456",
  //       processor: "ProcessorA",
  //       remarkMessage: "Initial setup",
  //       requestMode: "online",
  //       sid: "SID001",
  //       status: true,
  //       txnMaxAmount: 10000,
  //       txnMinAmount: 10
  //     },
  //     {
  //       prority: 2,
  //       domain: "testsite.org",
  //       mid: "MID789012",
  //       processor: "ProcessorB",
  //       remarkMessage: "Testing phase",
  //       requestMode: "offline",
  //       sid: "SID002",
  //       status: false,
  //       txnMaxAmount: 5000,
  //       txnMinAmount: 5
  //     },
  //     {
  //       prority: 3,
  //       domain: "demo.net",
  //       mid: "MID345678",
  //       processor: "ProcessorC",
  //       remarkMessage: "Demo account",
  //       requestMode: "online",
  //       sid: "SID003",
  //       status: true,
  //       txnMaxAmount: 20000,
  //       txnMinAmount: 20
  //     },
  //     {
  //       prority: 4,
  //       domain: "sample.biz",
  //       mid: "MID901234",
  //       processor: "ProcessorD",
  //       remarkMessage: "Sample data",
  //       requestMode: "offline",
  //       sid: "SID004",
  //       status: true,
  //       txnMaxAmount: 15000,
  //       txnMinAmount: 15
  //     },
  //     {
  //       prority: 5,
  //       domain: "demoapp.edu",
  //       mid: "MID567890",
  //       processor: "ProcessorE",
  //       remarkMessage: "Educational use",
  //       requestMode: "online",
  //       sid: "SID005",
  //       status: false,
  //       txnMaxAmount: 1000,
  //       txnMinAmount: 1
  //     }
  //   ]);

  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforDelete } =
    useApiServices();

  const FetchDataForRules = async (isPayout) => {
    console.log(isPayout);
    const response = await apiCallFnforGet(
      isPayout ? GET_PAYOUT_AMOUNT_RULES + mid : GET_AMOUNT_RULES + mid
    );
    // const response = await res.json()

    if (response?.statusCode === 200) {
    }
    const finalRes = response?.data?.map((item, index) => ({
      id: item.id,
      prority: index + 1,
      domain: item.domain,
      mid: item.mid,
      processor: item.processor,
      remarkMessage: item.remarkMessage,
      payoutMasterDataId: item?.payoutMasterDataId,
      requestMode: item.requestMode,
      sid: item.sid,
      status: item.isDenied,
      txnMaxAmount: item.txnMaxAmount,
      txnMinAmount: item.txnMinAmount,
    }));

    console.log("Response is here", finalRes);
    setData(finalRes);
  };

  useEffect(() => {
    FetchDataForRules(isPayout);
  }, [showRule]);

  const updateRules = async () => {
    try {
      const payloadForPayout = data.map((item) => ({
        prority: null,
        domain: item.domain,
        mid: item.mid,
        processor: item.processor,
        remarkMessage: item.remarkMessage,
        payoutMasterDataId: item.payoutMasterDataId,
        // requestMode: item.requestMode,
        sid: item.sid,
        isDenied: item.status ? true : false,
        txnMaxAmount: item.txnMaxAmount,
        txnMinAmount: item.txnMinAmount,
      }));
      const payload = data.map((item) => ({
        prority: null,
        domain: item.domain,
        mid: item.mid,
        processor: item.processor,
        remarkMessage: item.remarkMessage,
        // payoutMasterDataId: item.payoutMasterDataId,
        requestMode: item.requestMode,
        sid: item.sid,
        isDenied: item.status ? true : false,
        txnMaxAmount: item.txnMaxAmount,
        txnMinAmount: item.txnMinAmount,
      }));
      console.log("paload", payload);

      swal({
        title: "Alert",
        text: `Are you sure, you want to Update?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDisable) => {
        if (willDisable) {
          const response = await apiCallFnforPost(
            isPayout ? UPDATE_MID_PAYOUT_RULES : UPDATE_MID_RULES,
            isPayout ? payloadForPayout : payload
          );
          if (response.statusCode === 200) {
            swal({
              title: "Success",
              text: response.message,
              icon: "success",
            });
          } else {
            swal({
              title: "Alert",
              text: response.message,
              icon: "error",
            });
          }
        } else {
        }
      });
    } catch (error) {
      swal({
        title: "Alert",
        text: error,
        icon: "error",
      });
    }
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });

    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ""}`}
        style={{ cursor: "move", ...style }}
        {...restProps}
      ></tr>
    );
  };

  const columns = [
    // {
    //   title: "Prority",
    //   dataIndex: "prority",
    //   key: "prority",
    // },
    {
      title: "MID",
      dataIndex: "mid",
      key: "mid",
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Processor",
      dataIndex: "processor",
      key: "processor",
    },
    {
      title: "Remark Message",
      dataIndex: "remarkMessage",
      key: "remarkMessage",
    },
    {
      title: "Request Mode",
      dataIndex: "requestMode",
      key: "requestMode",
    },
    {
      title: "SID",
      dataIndex: "sid",
      key: "sid",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <button
          // Uncomment and customize style as needed
          style={{
            backgroundColor: status ? "#fc533f" : "#91fc6c",
            width: "70px",
            padding: "2px 3px",
            borderRadius: "20px",
            border: "none",
            fontWeight: "550",
            color: "#ffffff",
            cursor: "move",
          }}
          // onClick={() => handleRouting(status)}
        >
          {status ? "Denied" : "Route"}
        </button>
      ),
    },
    {
      title: "Txn Min Amount",
      dataIndex: "txnMinAmount",
      key: "txnMinAmount",
    },
    {
      title: "Txn Max Amount",
      dataIndex: "txnMaxAmount",
      key: "txnMaxAmount",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) =>
        !viewOnly && (
          <button
            // Uncomment and customize style as needed
            style={{
              backgroundColor: "red",
              width: "70px",
              padding: "2px 3px",
              borderRadius: "20px",
              border: "none",
              fontWeight: "550",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={() => handleRuleDelete(id)}
          >
            Delete
          </button>
        ),
    },
  ];

  const handleRuleDelete = async (id) => {
    try {
      swal({
        title: "Alert",
        text: `Are you sure, you want to Delete this rule?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDisable) => {
        if (willDisable) {
          console.log(isPayout, "isPayout2");
          // return
          const response = await apiCallFnforDelete(
            isPayout ? DISABLE_PAYOUT_RULE + id : DISABLE_RULE + id
          );
          if (response.statusCode === 200) {
            swal({
              title: "Success",
              text: response.message,
              icon: "success",
            });
            FetchDataForRules(isPayout);
          } else {
            swal({
              title: "Alert",
              text: response.message,
              icon: "error",
            });
          }
        } else {
        }
      });
    } catch (error) {
      swal({
        title: "Alert",
        text: error,
        icon: "error",
      });
    }
  };

  const offcanvasStyle = {
    width: "90vw", // Set width to 70vw
  };

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShowRule(false);
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      const newData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      const updatedData = newData.map((item, index) => ({
        ...item,
        // prority: index + 1,
      }));

      setData(updatedData);
    },
    [data]
  );

  const components = {
    body: {
      row: (props) => <DraggableBodyRow {...props} moveRow={moveRow} />,
    },
  };

  return (
    <>
      <Offcanvas
        show={showRule}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5
            class="offcanvas-title  fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            FRM and Routing Rules for {mid}
          </h5>
        </div>
        <Offcanvas.Body>
          <div className="">
            <DndProvider backend={HTML5Backend}>
              <Table
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                })}
                rowKey="prority" // Use prority as the key
              />
            </DndProvider>
            {!viewOnly && (
              <div>
                <button
                  className="badge badge-primary m-2 px-4 py-2"
                  onClick={updateRules}
                >
                  Update
                </button>
              </div>
            )}
            {/* <div>
        <h3>Updated Rows:</h3>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ShuffleRules;

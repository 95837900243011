import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col, Modal } from "react-bootstrap";
import DownloadCsv from "../../common/DownloadCsv";
import { headerheaderNamesForReconciliation } from "../../utils/DownloadHeaders";
import { getAllDailyTransactionDataReconciliation } from "../../utils/ApiServices";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import MidFiter from "./MidFiter";
import ReconMultiFilter from "../Tools/Merchant Management/ReconMultiFilter";
import ReconAdvanceSearch from "../Tools/Merchant Management/ReconAdvanceSearch";
import { Label } from "@mui/icons-material";
import FloatingReports from "../../common/FlotingReports";
import useApiServices from "../../utils/useApiServices";
import { GET_REPORT_API } from "../../utils/constant";
import MerchantIcon from "../../common/MerchantIcon";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Shimmer from "../../common/Shimmer/Shimmer";
import Filters from "./Filters";
import { debounce } from "lodash";
import { paginationCountCalc } from "../../utils/helper";
import NewDowloadCSV from "./NewDownloadCSV";
import DataNotFound from "../../common/DataNotFound/DataNotFound";

function Reconciliation({ type, toggleRightBar }) {
  const { apiCallFnforPostForQueryParams } = useApiServices();
  const [data, setData] = useState([]);
  const [dataM, setDataM] = useState([]);
  const [condition, setCondition] = useState("1 day");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [reset, setReset] = useState(false);
  const [show2, setShow2] = useState(false);
  const [mid, setMid] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [5, 10, 20, 30, 40, 50];
  const [changeCount, setChangeCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [successAmout, setSuccessAmount] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [txnSize, setTxnSize] = useState(0);
  const [txnSizeM, setTxnSizeM] = useState(0);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [isAdvance, setIsAdvance] = useState(false);


  const getDailyTransactionData = async () => {
    await apiCallFnforPostForQueryParams(
      GET_REPORT_API,
      {
        mid: mid,
        reportType: "reconciliation",
        timeFrame: condition,
      },
      {
        page:page-1,
        size,
      }
    )
      .then((response) => {
        setShowLoader(true)
        if (response.statusCode === 200) {
          setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
          setTxnSize( paginationCountCalc(response?.duplicateData?.txnCount || response?.duplicateData?.totaltxn));
          setSizeOfTransactions(response?.duplicateData?.txnCount);
          const finalRes = response?.data?.map((item, i) => ({
            id: i,
            mid: item.mid,
            companyName: item.companyName,
            extId: item.extId,
            date: item.date?.split(" ")[0],
            rrn: item.rrn,
            amount: item.amount,
            transactionStatus: item.transactionStatus,
            consolidatedStatus: item.consolidatedStatus,
            payinStatus: item.payinStatus,
          }));
          setData(finalRes);
          setDataM([]);
          setShowLoader(false)

        } else {
          setData([]);
          setShowLoader(false)
          setSuccessAmount("");
      
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };
  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData();
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      setCurrentPage(0);
      getDailyTransactionData();
    }
  };
  const columns = [
    { headerName: "Merchant ID", field: "mid", minWidth: "170" },
    { headerName: "Company Name", field: "companyName", minWidth: "240" },
    { headerName: "Transaction ID", field: "extId", minWidth: "270" },
    { headerName: "Date", field: "date", minWidth: "120" },
    { headerName: "UTR Number", field: "rrn", minWidth: "170" },
    { headerName: "Amount", field: "amount", minWidth: "160" },
    {
      headerName: "Transaction Status",
      field: "transactionStatus",
      minWidth: "150",
    },
    {
      headerName: "Consolidated Status",
      field: "consolidatedStatus",
      minWidth: "170",
    },
    { headerName: "Payin Status", field: "payinStatus", minWidth: "150" },
  ];
  //   const mappedData = data?.map((item) => {
  //     const mappedItem = {};
  //     columns.forEach((column) => {
  //       if (column.field === "date") {
  //         const dateValue = item["date"] ? item["date"].split(" ")[0] : "";
  //         mappedItem[column.field] = dateValue;
  //       } else if (column.field === "extId") {
  //         const extId = item["extId"] ? item["extId"]  : "";
  //         mappedItem[column.field] = extId;
  //       }else{
  //         mappedItem[column.field] = item[column.field];
  //       }
  //     });
  //     return mappedItem;
  //   });
  // const handleBackButtonClick = () => {
  //   navigate(-1);
  // };

  const handleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const HandleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleAdvanceFilter = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const HandleReset = () => {
    setReset(!reset);
    setMid("");
    setPageM(1);
    setPage(1);
    setDataM([]);
    setCondition("1 day");
    // getDailyTransactionData();
    setIsAdvance(false);
    setIsAdvancedSearchOpen(false);
  };

  // useEffect(() => {
  //   getDailyTransactionData();
  // }, [
  //   reset,
  //   currentPage,
  //   entriesPerPage,
  //   condition,
  //   setCondition,
  //   mid,
  //   setMid,
  //   page
  // ]);

  
  const debouncedMiscellenious =useCallback((
    debounce(()=>{
      getDailyTransactionData();
    },0)
  ), [reset, condition,page, mid, entriesPerPage, currentPage]
  )

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);


  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

  const handleClose = () => setShow(false);


  function CustomToolbar() {
   
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div className="d-flex justify-content-center gap-2">
            <GridToolbarExport className="badge badge-sm badge-primary text-white fw-bold" />
            <div
              onClick={() => setShow(true)}
              style={{ color: "#F6FAFD", cursor:"pointer" ,fontSize:"13px"}}
              className="d-flex align-items-center gap-1"
            >
              {" "}
              <span
                style={{ color: "#3C76D2", fontWeight: "900" }}
                className="mb-1"
              >
                {/* <i class="bi bi-download"></i> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#ff6e33"
                  class="bi bi-download fw-bold"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg> */}
              </span>

              {/* <span style={{ color: "#ff6e33", fontWeight: "500" }}>EXPORT {">"} 100</span> */}

            </div>

            
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start"><GridToolbarQuickFilter /></div>
          </div>
        </div>
      </GridToolbarContainer>
    );

    
  }



  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeM = (event, value) => {
    setPageM(value);
  };
  return (
    <>
      <div className="container-fluid">
        <MerchantIcon setMid={setMid}  reset={reset}/>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <section className="lincpay-dashboard-sec">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className='d-flex align-items-center'>
                        <button
                          type="button"
                          class="btn-close m-3"
                          onClick={toggleRightBar}
                        ></button>

                        <div className="fs-2 text-dark fw-bold">Reconcilation</div>
                      </div>
                      <div
                        className="col-7 mb-4 mb-sm-0"
                        style={{ textAlign: "right" }}
                      >
                        <IconWrapper title={"Zoomin"}>
                          <span class="" onClick={incrementZoom}>
                            <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                          </span>
                        </IconWrapper>
                        <IconWrapper title={"Zoomout"}>
                          <span class="" onClick={decrementZoom}>
                            <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                          </span>
                        </IconWrapper>
                        <IconWrapper title={"reset"}>
                          <i className="fa fa-refresh" onClick={HandleReset}></i>
                        </IconWrapper>
                        <IconWrapper title={"Multiple Filter"}>
                          <i
                            class="fa fa-filter"
                            onClick={HandleAdvanceFilter}
                          ></i>
                        </IconWrapper>
                        {/* <IconWrapper title={"Multiple Filter"}>
                          <i
                            class="fa fa-filter"
                            onClick={HandleMultipleFilter}
                          ></i>
                        </IconWrapper> */}
                      </div>
                    </div>

                    <div className="dasboard-view-count-boxes">
                      <Card.Body>
                        <div className="report-grid-view">
                          <div className="material-table-records mb-3"  style={{height:"500px"}}>
                            {
                              showLoader ? (
                                <Shimmer type="table" />
                              ) 
                              : (isAdvancedSearchOpen && isAdvance && dataM.length === 0) ? (
                                <div className="no-data-found">No data found for the selected filters.</div>
                              )
                              : (!isAdvancedSearchOpen && data.length === 0 && dataM.length === 0) ? (
                                // <div className="no-data-found">No data found.</div>
                                <DataNotFound />
                              )
                              :  !dataM?.length ?  (
                                <>
                                  <DataGrid
                                    columns={columns}
                                    rows={data}
                                    //  page={currentPage}
                                    // onPageChange={handlePageChange}
                                    slots={{
                                      toolbar: CustomToolbar
                                    }}
                                    hideFooterPagination={true}
                                    // className="mb-1 text-dark fw-bold"
                                    sx={{
                                      fontSize: `${zoomCount}px`,
                                      "& .css-1rtad1": {
                                        display: "block",
                                      },  // Adjust the font size as needed
                                    }}
                                  />
                                  <div style={{ display: "flex", justifyContent: "end", margin: "0.8rem" }}>

                                    <Pagination
                                      count={txnSize}
                                      page={page}
                                      onChange={handleChange}
                                    />
                                  </div>

                                </>


                              ) : (
                                <>
                                  <DataGrid
                                    rows={dataM}
                                    columns={columns}
                                    slots={{
                                      toolbar: CustomToolbar,
                                    }}
                                    hideFooterPagination={true}
                                    // className="mb-1 text-dark fw-bold"
                                    sx={{
                                      fontSize: `${zoomCount}px`, 
                                      "& .css-1rtad1": {
                                        display: "block",
                                      }, // Adjust the font size as needed
                                    }}
                                  />
                                  
        
                                    <Pagination
                                      count={txnSizeM}
                                      page={pageM}
                                      onChange={handleChangeM}
                                    />
                                  
                                </>
        
                              ) }

                          </div>
                          <div className="d-flex justify-content-between">
                            {/* <DownloadCsv DownLoadData={data} /> */}
                            {/* <div className="pagination-buttons d-flex justify-content-end gap-2">
                            <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                              // onClick={handlePreviousPage}
                              disabled={currentPage === 0}
                              onClick={() => setChangeCount(changeCount - 1)}
                            >
                              Previous
                            </button>
                            <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                              onClick={() => setChangeCount(changeCount + 1)}
                            >
                              Next
                            </button>
                          </div> */}
                          </div>

                        </div>
                      </Card.Body>
                      {/* <MidFiter 
            reportType={reportType}
            setShow2={setShow2}
            show2={show2}
            setLiveData={setData}
          /> */}
          {isAdvancedSearchOpen ? (
          <Filters
          setReconReportData={setDataM}
          setIsMultiFilterOpen={setIsMultipleFilterOpen}
          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
          type={type}
          mid={mid}
          setSizeM={setSizeM}
          sizeM={sizeM}
          pageM={pageM}
          setShowLoader={setShowLoader}
          setSuccessAmount={setSuccessAmount}
          setTxnSizeM={setTxnSizeM}
          setIsAdvance={setIsAdvance}
          />
          ): null}

                      {/* {isMultipleFilterOpen ? (
                        <ReconMultiFilter
                          setReconReportData={setData}
                          setIsMultiFilterOpen={setIsMultipleFilterOpen}
                        />
                      ) : isAdvancedSearchOpen ? (
                        <ReconAdvanceSearch
                          setReconReportData={setData}
                          setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                    {/* </Container> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FloatingReports
          type="reports"
          setCondition={setCondition}
          mid={mid}
          // reportType={type}
          setData={setData}
          reset={reset}
        />
        {show === true ? <NewDowloadCSV type="reconciliation" show={show} setShow={setShow} /> : null}
        {/* <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Reconciliation Report</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" className="fs-5 btn btn-md" onClick={handleClose}>
          Close
        </Button>
        <DownloadCsv
                    DownLoadData={data}
                    Name={`Live Reconciliation Report`}
                  />  
      </Modal.Footer>
    </Modal> */}

      </div>
    </>
  );
}

export default Reconciliation;

import axios from "axios";
// import { MDBDataTable } from "mdbreact";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import RouteIcon from "@mui/icons-material/Route";
import {
  SWIPELINC_API,
  GET_ALL_ROUTING_VIEW,
  GET_ALL_INITIAL_SID,
  GET_DETAILS,
  UPDATE_ALL_DATA_ADMIN_,
  ENABLE_DISABLE_TOOLS_PAYIN,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import MiniLoader from "../../common/MiniLoader/MiniLoader";
import { getAllRoutingView } from "../../utils/ApiServices";
// import NumberInWords from "../../../masters/NumberInWords";
import useCheckPrivilages from "../../utils/checkPrivilages";
import ToolsPayInRouting from "./PayinRouting";
import ToolsViewMappedSID from "./ViewMappedSID";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import PreviewIcon from "@mui/icons-material/Preview";
import { MenuItem, Select } from "@mui/material";

const ToolsSubmittedPayinRouting = ({ toggleRightBar, type1 }) => {
  const [applications, setApplications] = useState([]);
  const [mappedMerchant, setMappedMerchant] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModalViewMappedOpen, setIsModalViewMappedOpen] = useState(false);
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [SidData, setSidData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [checkTxnLimit, setCheckTxnLimit] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState([]);
  const [midToSearch, setMidToSearch] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [priority, setPriority] = useState([]);
  const { apiCallFnforGet, apiCallFnforPut, apiCallFnforPutForOpenUrl } =
    useApiServices();
  const [selectedAction, setSelectedAction] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [abc, setAbc] = useState("");

  const hasPrivilages2634 = useCheckPrivilages(2634);

  const handleCloseModal2 = () => {
    setIsModal2Open(false);
  };

  const handleCloseModalViewMapped = () => {
    setIsModalViewMappedOpen(false);
  };

  const handleShow = () => setIsModalViewMappedOpen(true);

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const showRoutingType = () => {
    if (selectedEditData?.routingType === "RR") return false;
    else return true;
  };
  console.log("check-->", abc);
  const columns = [
    { headerName: "Merchant ID", field: "mid", minWidth: 320 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 320 },
    { headerName: "Type", field: "type", minWidth: 150 },
    { headerName: "Action", field: "action", minWidth: 80 },
  ];

  const columnsForEditDetails = showRoutingType()
    ? [
        { headerName: "Merchant ID", field: "mid", minWidth: 100 },
        { headerName: "Merchant Name", field: "companyName", minWidth: 150 },
        { headerName: "SID", field: "sid", minWidth: 150 },
        { headerName: "Domain", field: "domain", minWidth: 70 },
        {
          headerName: "SID Company Name",
          field: "sidCompanyName",
          minWidth: 270,
        },
        {
          headerName: "Remaining SID Limit",
          field: "sidRemainigLimit",
          minWidth: 150,
        },
        {
          headerName: "Priority",
          field: "prority",
          minWidth: 120,
          renderCell: (params) => (
            <select
              className="form-control"
              value={params.value}
              onChange={(e) => handelEditPrority(params.row.id, e)}
            >
              <option value="0">Select</option>
              {priority.map((item, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          ),
        },
        {
          headerName: "Maximum Transaction Amount",
          field: "sidLimit",
          minWidth: 200,
          renderCell: (params) => (
            <input
              className="form-control"
              name={params.row.id}
              type="number"
              onChange={(e) => handelEditMaxTxnAmtInput(params.row.id, e)}
              value={params.value}
            />
          ),
        },
        {
          headerName: "Action",
          field: "action",
          minWidth: 250,
          renderCell: (params) => (
            <div className="d-flex gap-2 justify-content-evenly ">
              {params.row.status ? (
                hasPrivilages2634 ? (
                  <button
                    className="badge badge-rounded badge-danger mx-2 px-3 py-2 mt-2"
                    onClick={() => EnaDisa(params.row.id, "disable")}
                  >
                    Disable
                  </button>
                ) : null
              ) : (
                <button
                  className="badge badge-rounded badge-success"
                  onClick={() => EnaDisa(params.row.id, "enable")}
                >
                  Enable
                </button>
              )}
            </div>
          ),
        },
      ]
    : [
        { headerName: "Merchant ID", field: "mid", minWidth: 250 },
        { headerName: "Merchant Name", field: "companyName", minWidth: 250 },
        { headerName: "SID", field: "sid", minWidth: 250 },
        { headerName: "Domain", field: "domain", minWidth: 250 },
        {
          headerName: "SID Company Name",
          field: "sidCompanyName",
          minWidth: 250,
        },
        {
          headerName: "Remaining SID Limit",
          field: "sidRemainigLimit",
          minWidth: 250,
        },
        {
          headerName: "Maximum Transaction Amount",
          field: "sidLimit",
          minWidth: 250,
          renderCell: (params) => (
            <input
              className="form-control"
              name={params.row.id}
              type="number"
              onChange={(e) => handelEditMaxTxnAmtInput(params.row.id, e)}
              value={params.value}
            />
          ),
        },
        {
          headerName: "Action",
          field: "action",
          minWidth: 250,
          renderCell: (params) => (
            <div className="d-flex gap-2 justify-content-evenly">
              {params.row.status ? (
                hasPrivilages2634 ? (
                  <button
                    className="badge badge-rounded badge-danger mx-2 px-3 py-2 mt-2"
                    onClick={() => EnaDisa(params.row.id, "disable")}
                  >
                    Disable
                  </button>
                ) : null
              ) : (
                <button
                  className="badge badge-rounded badge-success mx-2 px-3 py-2 mt-2"
                  onClick={() => EnaDisa(params.row.id, "enable")}
                >
                  Enable
                </button>
              )}
            </div>
          ),
        },
      ];

  const columnsForDetails = [
    { headerName: "Merchant ID", field: "mid", minWidth: 150 },
    { headerName: "Merchant Name", field: "companyName", minWidth: 250 },
    { headerName: "SID", field: "sid", minWidth: 230 },
    { headerName: "Domain", field: "domain", minWidth: 150 },
    { headerName: "SID Company Name", field: "sidCompanyName", minWidth: 250 },
    {
      headerName: "Maximum Transaction Amount",
      field: "sidLimit",
      minWidth: 250,
    },
  ];

  const mappedData = applications?.map((item) => {
    // const mappedItem = { ...item };
    const mappedItem = {
      id: item.id,
      mid: item.mid,
      sid: item.sid,
      sidLimit: item.sidLimit,
      companyName: item.companyName,
      status: item.status,
      flag: item.flag,
      sidCompanyName: item.sidCompanyName,
      routingType: item.routingType,
      sidRemainigLimit: item.sidRemainigLimit,
      prority: item.prority,
      domain: item.domain,
      deleted: item.deleted,
      type:
        item.type == "offline" ? (
          <div className="badge badge-rounded badge-warning">{item.type}</div>
        ) : (
          <div className="badge badge-rounded badge-success">online</div>
        ),
      typeForAction: item.type,
    };
    console.log(mappedItem);
    mappedItem.maxTxnAmount = (
      <span>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </span>
    );
    if (mappedItem.typeForAction != "offline") {
      if (mappedItem.status === true) {
        mappedItem.action = hasPrivilages2634 ? (
          <div className="d-flex gap-2 justify-content-evenly mt-2">
            <Select
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
              displayEmpty
              renderValue={() => null}
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem
                value="edit"
                onClick={() => {
                  openDetails(
                    mappedItem.mid,
                    mappedItem.companyName,
                    mappedItem.sid,
                    item.sidLimit,
                    item.id,
                    true,
                    item.routingType,
                    item.prority,
                    item.domain
                  );
                  // setAbc(mappedItem)
                  setSelectedEditData(mappedItem);
                  Details(item.mid, "edit");
                }}
              >
                Edit
              </MenuItem>
              {/* <button
            className="badge badge-rounded badge-warning mx-2 px-3 py-2"
            onClick={() => {
              openDetails(
                mappedItem.mid,
                mappedItem.companyName,
                mappedItem.sid,
                item.sidLimit,
                item.id,
                true,
                item.routingType,
                item.prority,
                item.domain
              );
              setSelectedEditData(mappedItem);
              Details(item.mid, "edit");
            }}
            >
            Edit
          </button> */}
              <MenuItem
                value="view"
                onClick={() => {
                  Details(item.mid, "details");
                }}
              >
                View
              </MenuItem>

              {/* <button
            className="badge badge-rounded badge-primary"
            onClick={() => {
              Details(item.mid, "details");
            }}
            >
            View
          </button> */}
            </Select>
          </div>
        ) : null;
      }

      if (mappedItem.status === false) {
        mappedItem.action = hasPrivilages2634 ? (
          <div className="d-flex gap-2 justify-content-evenly mt-2">
            <Select
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
              displayEmpty
              renderValue={() => null}
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem
                value="edit"
                onClick={() => {
                  openDetails(
                    mappedItem.mid,
                    mappedItem.companyName,
                    mappedItem.sid,
                    item.sidLimit,
                    item.id,
                    false,
                    item.routingType,
                    item.prority
                  );
                  // setAbc(mappedItem)
                  setSelectedEditData(mappedItem);
                  Details(item.mid, "edit");
                }}
              >
                Edit
              </MenuItem>

              <MenuItem
                value="view"
                onClick={() => Details(item.mid, "details")}
              >
                View
              </MenuItem>
            </Select>
          </div>
        ) : null;
      }
    }
    return mappedItem;
  });
  // console.log(abc)
  // console.log("Edit data-->",selectedEditData)
  // console.log("App--->",applications)

  const handelEditMaxTxnAmtInput = (id, e) => {
    let typedData = editFormData?.filter((elem) => {
      return elem.id === id;
    });
    typedData[0].sidLimit = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditPrority = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].prority = parseInt(e.target.value);

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const handelEditRoutingType = (id, e) => {
    let typedData = editFormData.filter((elem) => {
      return elem.id === id;
    });

    typedData[0].routingType = e.target.value;

    let remainingData = editFormData.filter((elem) => {
      return elem.id !== id;
    });
    setEditFormData([...remainingData, typedData[0]]);
  };

  const editMappedData = mappedMerchant?.map((item) => ({
    id: item.id,
    mid: item.mid,
    companyName: item.companyName,
    sid: item.sid,
    domain: item.domain,
    sidCompanyName: item.sidCompanyName,
    sidRemainigLimit: item.sidRemainigLimit,
    sidLimit: item.sidLimit,
    prority: item.prority,
    status: item.status,
  }));

  const getAllInitialSID = async () => {
    await apiCallFnforGet(GET_ALL_INITIAL_SID)
      .then((response) => {
        // setShowLoader(false)
        if (response.statusCode === 200) {
          setSidData(response?.data);
          let res = response?.data?.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "" };
          });

          let selectedData = response.data.filter(
            (elem) => editFormData.sid === elem.sid
          );
          setCheckTxnLimit(selectedData[0]);
          setSidData(res);
        } else {
          console.error("Error fetching data:", response.data.message);
        }

        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const Details = (mid, openModel) => {
    setMidToSearch(mid);
    // setShowLoader(true)
    apiCallFnforGet(GET_DETAILS + mid)
      .then((response) => {
        // setShowLoader(false);
        if (response.statusCode === 200) {
          setMappedMerchant(response?.data);
          setEditFormData(response?.data);
          setPriority(response?.data);
        } else {
          swal({
            title: "Alert",
            text: "No data found",
            icon: "warning",
          });
          setIsModal2Open(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setShowLoader(false);
      });

    if (openModel === "edit") setIsModalOpen(true);
    else setIsModal2Open(true);
  };

  const openDetails = (
    mid,
    companyName,
    sid,
    sidLimit,
    id,
    status,
    routingType,
    prority,
    domain
  ) => {
    setEditFormData({
      sidLimit: sidLimit,
      mid,
      merchantName: companyName,
      sid,
      routingType: routingType,
      id,
      status,
      prority: parseInt(prority),
      prority: parseInt(prority),
      domain: domain,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const EnaDisa = (id, enableOrDisable) => {
    swal({
      title: "Alert",
      text: `Are you sure, you want to ${enableOrDisable}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(
          `${ENABLE_DISABLE_TOOLS_PAYIN}${enableOrDisable}/${id}`,
          formData,
          true
        )
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text: `SID ${enableOrDisable} Successfully.`,
                icon: "success",
              });
              handleCloseModal();
              Details(midToSearch, "edit");
            } else if (res.statusCode === 306) {
              swal({
                title: "Alert!",
                text: res.message,
                icon: "error",
              });
            } else {
              swal({
                title: "Success",
                // text: `Failed to ${enableOrDisable} Aggregator.`,
                text: `Failed to ${enableOrDisable} SID.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    });
  };

  function areArrayValuesUnique(editFormData) {
    if (selectedEditData?.routingType === "SQ") {
      // Check if routing pattern is "Sequential"
      const PriorityArr = editFormData?.map((item) => {
        return item?.prority;
      });

      // Use a Set to track unique values
      const uniqueSet = new Set(PriorityArr);

      // If the size of the Set is equal to the length of the array, all values are unique
      return uniqueSet.size === PriorityArr?.length;
    }
    // If routing pattern is not "Sequential," return true to bypass this check
    return true;
  }

  function IsAllPrioritiesSelect(editFormData) {
    if (selectedEditData?.routingType === "SQ") {
      const PriorityArr = editFormData?.map((item) => {
        return item?.prority;
      });

      return PriorityArr.includes(0);
    }
    // If routing pattern is not "Sequential," return false to bypass this check
    return false;
  }

  function IsRoutingPatternSelect(editFormData) {
    const RoutingArr = editFormData?.map((item) => {
      return item?.routingType;
    });
    return RoutingArr.includes("");
  }
  //work here
  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    const IsPrioritiesUnique = areArrayValuesUnique(editFormData);
    const IsPrioritiesSelected = IsAllPrioritiesSelect(editFormData);
    const IsRoutingPatternSelected = IsRoutingPatternSelect(editFormData);

    if (!IsPrioritiesUnique) {
      swal({
        title: "ALERT!",
        text: "Priorities Cannot Be Same.",
        icon: "error",
      });
      return;
    }
    if (IsPrioritiesSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Priorities.",
        icon: "error",
      });
      return;
    }
    if (IsRoutingPatternSelected) {
      swal({
        title: "ALERT!",
        text: "Please Select Routing Pattern.",
        icon: "error",
      });
      return;
    }
    const payload = editFormData?.map((elem) => {
      return {
        sidLimit: elem.sidLimit,
        mid: elem.mid,
        sid: elem.sid,
        routingType: selectedEditData?.routingType || "",
        processor: "",
        prority: elem.prority,
        id: elem.id,
        status: elem.status,
        flag: elem.flag,
        domain: elem.domain,
      };
    });
    setShowLoader(true);
    // handleCloseModal();
    apiCallFnforPut(UPDATE_ALL_DATA_ADMIN_, payload)
      .then((response) => {
        setShowLoader(true);

        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.message,
            icon: "success",
          });

          handleCloseModal();
          // FetchData(token);
          setShowLoader(false);
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  const FetchData = async () => {
    // setShowLoader(true);
    try {
      const apiResponse = await apiCallFnforGet(GET_ALL_ROUTING_VIEW);
      // setShowLoader(false)
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);

        console.log(apiResponse.data);
      } else {
      }
    } catch (error) {
      // setShowLoader(false);
    }
  };

  const handelRoutingChange = (e) => {
    setSelectedEditData({ ...selectedEditData, routingType: e.target.value });
  };

  useEffect(() => {
    FetchData();
  }, [isModalOpen, setIsModalOpen, isModalRoutingOpen]);

  useEffect(() => {
    getAllInitialSID();
  }, [editFormData.id]);

  console.log(selectedEditData?.routingType);

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between bg-white  ">
          <div className="d-flex justify-content-start align-items-center mt-2">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Pay-in Routing</div>
          </div>
          <div className="d-flex gap-2 justify-content-end me-4 mt-4">
            <CustomTip size="18" placement="top" title=" Routing">
              <div className="" onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>

            <CustomTip size="18" placement="top" title="  View Mapped SID">
              <div className="" onClick={handleShow}>
                <PreviewIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={mappedData}
                      // columns={columns}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "active" ||
                          column.field === "action" ||
                          column.field === "type"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EDIT FORM MODEL STARTS */}
      {showLoader ? (
        <MiniLoader />
      ) : (
        <Modal
          show={isModalOpen}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-4 fw-bold">Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Card className="shadow border-0 mb-4"> */}
            {/* <Card.Body> */}
            <div className="col-sm-12 col-md-6 mt-2">
              <label htmlFor="email" className="fs-4 text-dark">
                Routing Pattern
              </label>
              <select
                id=""
                className="form-control"
                onChange={handelRoutingChange}
                value={selectedEditData?.routingType}
                name="routingPattern"
              >
                <option value="">Select a Pattern</option>
                <option
                  value="RR"
                  selected={selectedEditData?.routingType === "RR"}
                >
                  Round Robin
                </option>
                <option
                  value="SQ"
                  selected={selectedEditData?.routingType === "SQ"}
                >
                  Sequential
                </option>
              </select>
            </div>

            <div className="merchant-report-view">
              <div className="report-table-view">
                <div className="containerflag ">
                  <div className="row mt-4 ">
                    <DataGrid
                      className="dataTable"
                      rows={editMappedData}
                      columns={columnsForEditDetails.map((column) => ({
                        ...column,
                        sortable: true,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-2 ">
                    <button
                      className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                      onClick={handleUpdateSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* </Card.Body> */}
            {/* </Card> */}
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={isModal2Open}
        onHide={handleCloseModal2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold"> Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card className="shadow border-0 mb-4"> */}
          {/* <Card.Body> */}
          <div className="">
            <div className="">
              <div className="">
                <div className=" ">
                  <DataGrid
                    rows={mappedMerchant}
                    columns={columnsForDetails}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </Card.Body> */}
          {/* </Card> */}
        </Modal.Body>
      </Modal>

      {/* Modal for view mapped SID */}

      <Modal
        show={isModalViewMappedOpen}
        onHide={handleCloseModalViewMapped}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">View Mapped SID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card.Body> */}
          <ToolsViewMappedSID />
          {/* </Card.Body> */}
        </Modal.Body>
      </Modal>

      {/* Modal Routing */}

      {/* <Card.Body> */}
      <ToolsPayInRouting
        show={isModalRoutingOpen}
        onHide={handleCloseModalRouting}
      />
    </>
  );
};

export default ToolsSubmittedPayinRouting;

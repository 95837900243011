import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Offcanvas, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  GET_ALL_PAYMENT_SERVICES,
  APPROVED_MOBILE_APP_ONBOARD,
  VIEW_FILE_UPDATE_STATUS_API,
  SAVE_SELECTED_PAYMENT_SERVICES_API,
} from "../../utils/constant";

import { Container, Row, Col, Card } from "react-bootstrap";
import { fetchAllMerchantMaster } from "../../utils/ApiServices";
import { useSelector } from "react-redux";
import DocumentList from "./DocumentList";
import VideosList from "./VideosList";
import useApiServices from "../../utils/useApiServices";
import RejectionComponent from "./RejectionComponent";

const ViewDetailsMobileApp = ({
  id,
  data,
  showMobileApp,
  onHide,
  setRefresh,
  refresh,
}) => {
  console.log(id);
  const navigate = useNavigate();
  const { privilagesData, prefix, type, masterId } = useSelector(
    (state) => state.privilages
  );
  console.log(masterId);
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();
  const [merchantData, setMerchantData] = useState(data);
  const [videoNames, setVideoNames] = useState([]);
  const [documentsNames, setDocumentsNames] = useState([]);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(Boolean);
  const [paymentservice, setServices] = useState();
  const [selectedServices, setSelectedServices] = useState(
    data?.paymentService.map((item) => {
      return item.paymentService;
    })
  );
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [commissionPercentageInput, setCommissionPercentageInput] =
    useState("");
  const token = localStorage.getItem("token");
  const [merchantMaster, setMerchantMaster] = useState("");
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [mccCode, setMccCode] = useState("");
  const offcanvasStyle = {
    width: "90vw",
  };

  const handleApprove = () => {
    if (merchantMaster === "" && prefix === "ALL") {
      swal({
        title: "ALERT",
        text: "Please Select Merchant Master.",
        icon: "error",
      });
      return;
    }
    if (mccCode === "") {
      swal({
        title: "ALERT",
        text: "Please Select MCC Code.",
        icon: "error",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to approve this Application?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          applicationStatus: "Approved",

          id: id,
          rejectionRemark: "NA",
          masterMerchantId: prefix === "ALL" ? merchantMaster : masterId,
          commissionPercentage: parseFloat(commissionPercentageInput) || 0,
          mccCode: mccCode,
        };

        // axios
        // .post(${SWIPELINC_API}MerchantAdminPanel/yesBank/update, payload, {
        //   headers: {
        //     Authentication: Bearer ${token},
        //   },
        // })
        apiCallFnforPost(APPROVED_MOBILE_APP_ONBOARD, payload)
          .then((response) => {
            console.log("dataa", response);
            if (response.statusCode == 200) {
              swal("Success", "Application approved!", "success");
              setRefresh(!refresh);
              setOpen(false);
              onHide();
            } else {
              swal("Error", "Failed to approve the application.", "error");
            }
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
            swal("Error", "Something went wrong. Please try again.", "error");
          });
      } else {
        setOpen(false);
      }
    });
  };

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      // content: {
      //   element: "input",
      //   attributes: {
      //     type: "text",
      //     placeholder: "Enter rejection remark",

      //   },
      // },
    }).then(() => {
      // if (remark === "") {
      //   swal("Warning", "Please fill in the rejection remark.", "warning");
      // } else if (remark) {
      const payload = {
        applicationStatus: "rejected",
        bankVerified: true,
        registrationId: id,
        rejectionRemark: "",
      };

      apiCallFnforPut(VIEW_FILE_UPDATE_STATUS_API, payload)
        .then((response) => {
          swal("Success", "Application Rejected!", "success").then(() => {
            setOpen(false);
            handleClose();
          });
        })
        .catch((error) => {
          swal("Failed", "Failed to Reject", "error").then(() => {
            setOpen(false);
            handleClose();
          });
          console.error("Error rejecting the application:", error.message);
        });
      // } else {
      //   setOpen(false);
      //   handleClose();
      // }
    });
  };

  // const handleReject = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Do you want to reject this vendor?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, reject it!",
  //     input: "text",
  //     inputPlaceholder: "Enter rejection remark",

  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const remark = result.value;
  //       const payload = {
  //         applicationStatus: "rejected",
  //         bankVerified: true,
  //         registrationId: id,
  //         rejectionRemark: remark,
  //       };

  //       apiCallFnforPut(VIEW_FILE_UPDATE_STATUS_API, payload)
  //         .then((response) => {
  //           if (response.statusCode === 200) {
  //             Swal.fire("Success", "Application Rejected!", "success").then(
  //               () => {
  //                 setOpen(false);
  //                 setRefresh(!refresh);
  //                 onHide();
  //               }
  //             );
  //           } else {
  //             Swal.fire("Error", "Failed to reject the application.", "error");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error rejecting the application:", error.message);
  //           Swal.fire(
  //             "Error",
  //             "Something went wrong. Please try again.",
  //             "error"
  //           );
  //         });
  //     } else {
  //       setOpen(false);
  //     }
  //   });
  // };

  const handleSave = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to save ?",
      icon: "warning",
      buttons: ["Cancel", "Yes, Save it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const ids = [];
        console.log(paymentservice);

        for (let i = 0; i < selectedServices.length; i++) {
          const payId = paymentservice.find(
            (item) => item.paymentService === selectedServices[i]
          );
          console.log(payId);
          ids.push(payId.paymentTypeId);
        }
        const payload = {
          paymentTypeId: ids,
          registrationId: id,
        };
        console.log(payload);
        // axios
        //   .put(
        //     ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/${id},
        //     payload,
        //     {
        //       headers: {
        //         Authentication: Bearer ${token},
        //       },
        //     }
        //   )
        apiCallFnforPut(SAVE_SELECTED_PAYMENT_SERVICES_API + id, payload)
          .then((response) => {
            swal("Success", "Services Saved Successfully", "success").then(
              () => {
                setOpen(false);
                setRefresh(!refresh);
              }
            );
          })
          .catch((error) => {
            console.error("Error updating payment services:", error.message);
          });
      } else {
        setOpen(false);
      }
    });
  };

  const handleServiceChange = (e) => {
    const found = selectedServices?.find((item) => item === e.target.value);
    if (found) {
      setSelectedServices(selectedServices?.filter((item) => item !== found));
    } else {
      setSelectedServices([...selectedServices, e.target.value]);
    }
  };

  useEffect(() => {
    const getAllMerchantMaster = async () => {
      try {
        const response = await fetchAllMerchantMaster(token);
        if (response?.statusCode === 200) {
          setMerchantMasterData(response?.data);
        } else {
          setMerchantMasterData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllMerchantMaster();
  }, []);

  const getAllPaymentServices = async () => {
    try {
      const response = await axios.get(GET_ALL_PAYMENT_SERVICES);

      if (response?.data?.statusCode === 200) {
        setServices(response?.data?.data);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPaymentServices();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
    onHide();
  };

  const array = [
    { label: "Company Name", value: merchantData?.companyName },
    {
      label: "Registration Type",
      value: merchantData?.registrationType,
    },
    { label: "Business Category", value: merchantData?.businessType },
    {
      label: "Other Business Category",
      value: merchantData?.businessTypeName,
    },
    {
      label: "Client Type",
      value: merchantData?.typesOfMerchant,
    },
    {
      label: "Turn-Over Type",
      value: merchantData?.turnoverType,
    },
    { label: "Product Name", value: merchantData?.businessAs },
    { label: "Business Type", value: merchantData?.companyType },

    { label: "UDYOG Aadhaar Number", value: merchantData?.orgUdyogAadhar },
    { label: "Latitude", value: merchantData?.latitute },

    { label: "Longitude", value: merchantData?.Longitude },
    { label: "Account Number", value: merchantData?.merchantAccountNumber },
    { label: "Bank Name", value: merchantData?.merchantBankName },
    { label: "Branch Name", value: merchantData?.bankBranchName },
    { label: "IFSC Code", value: merchantData?.merchantIfscCode },
    {
      label: "Company PAN Number",
      value: merchantData?.companyPanNumber,
    },

    { label: "GST Number", value: merchantData?.gstNumber },
    {
      label: "Authorized Person Name",
      value: merchantData?.authorizedPersonName,
    },

    { label: "e-Mail", value: merchantData?.email },
    { label: "Mobile Number", value: merchantData?.mobile },
    {
      label: "Authorized Person PAN",
      value: merchantData?.authorizedPersonPan,
    },
    { label: "Aadhaar Number", value: merchantData?.aadharNumber },
    { label: "Website URL", value: merchantData?.websiteUrl },
    { label: "Application URL", value: merchantData?.aapUrl },
    {
      label: "Payment Services",
      value:
        merchantData?.status === "Submitted" ? (
          <div>
            <button
              className="badge badge-warning bg-warning px-3"
              onClick={() =>
                setShowPaymentServicesList(!showPaymentServicesList)
              }
              style={{ marginRight: "10px" }} // Added marginRight for spacing
            >
              {showPaymentServicesList ? "Hide" : "View"}
            </button>
            {showPaymentServicesList && (
              <div className="payment-services-list row">
                {paymentservice?.map((service) => (
                  <div
                    key={service.paymentTypeId}
                    className="form-check col-4 mt-1"
                    style={{ marginRight: "18px", marginTop: "10px" }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        border: "1px solid grey",
                        scale: "1",
                      }}
                      className="form-check-input"
                      id={`service-${service.paymentTypeId}`}
                      value={service.paymentService}
                      checked={selectedServices.includes(
                        service.paymentService
                      )}
                      onChange={handleServiceChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`service-${service.paymentTypeId}`}
                    >
                      {service.paymentService}
                    </label>
                  </div>
                ))}
                <div className="col-12 mt-2">
                  <button
                    className="badge badge-rounded badge-success"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="horizontal-table-row">
            <div className="horizontal-table-cell text-left"></div>
            <div className="horizontal-table-cell-value text-left">
              {selectedServices.map((service, index) => (
                <div key={index} style={{ marginBottom: "5px" }}>
                  {service}
                </div>
              ))}
            </div>
          </div>
        ),
    },
    {
      label: "Company Registered Address",
      value: merchantData?.companyAddress,
    },
    { label: "Pin Code", value: merchantData?.companyAddressPinCode },
    { label: "City", value: merchantData?.companyAddressCity },
    { label: "State", value: merchantData?.companyAddressState },
    {
      label: "Authorized Signatory Address",
      value: merchantData?.registeredOfficeAddress,
    },
    {
      label: "Pin Code",
      value: merchantData?.registeredOfficeAddressPinCode,
    },
    {
      label: "City",
      value: merchantData?.registeredOfficeAddressCity,
    },
    {
      label: "State",
      value: merchantData?.registeredOfficeAddressState,
    },
    {
      label: "Company Communication Address",
      value: merchantData?.communicationAddress,
    },
    {
      label: "Pin Code",
      value: merchantData?.communicationAddressPinCode,
    },
    { label: "City", value: merchantData?.communicationAddressCity },
    {
      label: "State",
      value: merchantData?.communicationAddressState,
    },
    {
      label: "Mode of Registration",
      value: merchantData?.modeOfRegistration,
    },
    {
      label: "Modification Date",
      value: merchantData?.modificationDate,
    },
    { label: "Submission Date", value: merchantData?.submitDate },
    { label: "Submission Time", value: merchantData?.submitTime },
    { label: "Referred By", value: merchantData?.referredBy },
    {
      label: "Commission Percentage",
      value:
        merchantData?.referredBy !== null ? (
          <div className="vertical-table-row">
            <div className="vertical-table-cell text-left">
              Commission Percentage
            </div>
            <div className="vertical-table-cell-value">
              <input
                className="commision-per"
                type="text"
                value={commissionPercentageInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!inputValue || parseFloat(inputValue) >= 0) {
                    setCommissionPercentageInput(inputValue);
                  }
                }}
                placeholder="Enter commission percentage"
              />
              {parseFloat(commissionPercentageInput) < 0 && (
                <p className="error-message">
                  Commission percentage must be positive
                </p>
              )}
            </div>
          </div>
        ) : null,
    },
    {
      label: "Merchant Master",
      value:
        merchantData?.status === "Submitted" ? (
          <select
            onChange={(e) => {
              setMerchantMaster(e.target.value);
            }}
            value={merchantMaster}
            className="mt-3"
          >
            <option value="">Select a Merchant</option>
            {merchantMasterData?.map((merchant) => (
              <option
                key={merchant.masterMerchantId}
                value={merchant.masterMerchantId}
              >
                {merchant.name}
              </option>
            ))}
          </select>
        ) : (
          <div className="vertical-table-row">
            <div className="vertical-table-cell-value  text-left">
              {merchantData?.masterMerchant}
            </div>
          </div>
        ),
    },
    {
      label: "MCC Code",
      value:
        merchantData?.status === "Submitted" ? (
          <select
            onChange={(e) => {
              setMccCode(e.target.value);
            }}
            value={mccCode}
            className="mt-3"
          >
            <option value="">Select an MCC Code</option>
            <option value="gaming-101">Gaming 101</option>
            <option value="gaming-203">Gaming 203</option>
            <option value="gaming-204">Gaming 204</option>
            <option value="ecommerce-101">Ecommerce 101</option>
            <option value="ecommerce-103">Ecommerce 103</option>
            <option value="5262">5262</option>
            <option value="5816">5816</option>
            <option value="4900">4900</option>
            <option value="6300">6300</option>
          </select>
        ) : (
          <div className="vertical-table-row">
            <div className="vertical-table-cell-value  text-left">
              {merchantData?.mccCode}
            </div>
          </div>
        ),
    },
  ];

  const showRemarkPopup = (data, data2) => {
    swal({
      title: "Add Remark",
      text: `Please enter your remark for ${data.toLowerCase()} below:`,
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your remark here...",
          type: "text",
          value: data2,
        },
      },
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "btn btn-danger",
        },
        confirm: {
          text: "Reject",
          className: "btn btn-warning",
        },
      },
    }).then((value) => {
      if (value === null) {
        // Cancel button clicked
        console.log("Cancel clicked");
      } else {
        // Revert button clicked
        console.log("Revert clicked with remark:", value);
        // Add your logic here for handling the remark
      }
    });
  };

  const selectedervices = [
    {
      paymentTypeId: 1,
      paymentService: "QR",
      mdrPercentage: 1.2,
      active: true,
    },
    {
      paymentTypeId: 9,
      paymentService: "Payout",
      mdrPercentage: 1.2,
      active: true,
    },
    {
      paymentTypeId: 23,
      paymentService: "UPI",
      mdrPercentage: 1.2,
      active: true,
    },
    {
      paymentTypeId: 8,
      paymentService: "AEPS",
      mdrPercentage: 1.2,
      active: true,
    },
  ];

  const typeOfCategories = "Nutrition & Supplement, Rental, Shoes";

  return (
    <div>
      <Offcanvas
        show={showMobileApp}
        placement="end"
        style={offcanvasStyle}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Offcanvas.Title class="d-flex">
          <Offcanvas.Header
            class="d-flex align-items-center"
            closeButton
            onClick={handleClose}
          ></Offcanvas.Header>
          <div class="fs-2 text-dark fw-bold">Mobile App On-Boarding</div>
        </Offcanvas.Title>

        <Offcanvas.Body className="bg-light">
          <table className="table table-bordered border-dark">
            <tbody>
              <tr>
                <td className="fw-bold">Company Name</td>
                <td>{merchantData?.companyName}</td>
                <td className="fw-bold">Registration Type</td>
                <td>{merchantData?.registrationType}</td>
              </tr>
              <tr>
                <td className="fw-bold">Clint Type</td>
                <td>{/* {merchantData?.companyName} */}</td>
                <td className="fw-bold">Business Category</td>
                <td>{/* {merchantData?.registrationType} */}</td>
              </tr>
              <tr>
                <td className="fw-bold">OTHER BUSINESS CATEGORY NAME.</td>
                <td>{/* {merchantData?.companyName} */}</td>
                <td className="fw-bold">TYPES OF CATEGORY.</td>
                <td>{/* {merchantData?.registrationType} */}</td>
              </tr>
              <tr>
                <td className="fw-bold">TURNOVER TYPE</td>
                <td>{/* {merchantData?.businessType} */}</td>
                <td className="fw-bold">PRODUCT NAME</td>
                <td>{/* {merchantData?.companyType} */}</td>
              </tr>
              <tr>
                <td className="fw-bold">PRIMARY DIRECTOR NAME</td>
                <td>{/* {merchantData?.businessType} */}</td>
                <td className="fw-bold">YDYOG AADHAAR NUMBER</td>
                <td>{merchantData?.orgUdyogAadhar}</td>
              </tr>
              <tr>
                <td className="fw-bold">LATITUDE</td>
                <td>{merchantData?.latitute}</td>
                <td className="fw-bold">LONGITUDE</td>
                <td>{merchantData?.longitute}</td>
              </tr>
              <tr>
                {/* <td className="fw-bold">BANK ACCOUNT NAME </td>
      <td>{merchantData?.businessType}</td> */}
                <td className="fw-bold">ACCOUNT NUMBER</td>
                <td>{merchantData?.merchantAccountNumber}</td>
              </tr>
              <tr>
                <td className="fw-bold">BANK NAME</td>
                <td>{merchantData?.merchantBankName}</td>
                <td className="fw-bold">BRANCH NAME</td>
                <td>{merchantData?.bankBranchName}</td>
              </tr>

              <tr></tr>
              <tr>
                <td className="fw-bold">IFSC Code</td>
                <td>{merchantData?.merchantIfscCode}</td>
                <td className="fw-bold">Business Type</td>
                <td>{merchantData?.businessType}</td>
              </tr>
              <tr>
                <td className="fw-bold">Business As</td>
                <td>{merchantData?.businessAs}</td>
                <td className="fw-bold">Company PAN Number</td>
                <td>{merchantData?.companyPanNumber}</td>
              </tr>
              <tr>
                <td className="fw-bold">GST Number</td>
                <td>{merchantData?.gstNumber}</td>
                <td className="fw-bold">Authorized Person Name</td>
                <td>{merchantData?.authorizedPersonName}</td>
              </tr>
              <tr>
                <td className="fw-bold">e-Mail ID</td>
                <td>{merchantData?.email}</td>
                <td className="fw-bold">Mobile Number</td>
                <td>{merchantData?.mobile}</td>
              </tr>
              <tr>
                <td className="fw-bold">Authorized Person PAN</td>
                <td>{merchantData?.authorizedPersonPan}</td>
                <td className="fw-bold">Aadhaar Number</td>
                <td>{merchantData?.aadharNumber}</td>
              </tr>
              <tr>
                <td className="fw-bold">Website URL</td>
                <td>{merchantData?.websiteUrl}</td>
                <td className="fw-bold">Application URL</td>
                <td>{merchantData?.aapUrl}</td>
              </tr>
              <tr>
                <td className="fw-bold">Services</td>
                <td colSpan="3">
                  <button
                    className="badge badge-rounded badge-primary"
                    onClick={() =>
                      setShowPaymentServicesList(!showPaymentServicesList)
                    }
                  >
                    View Services
                  </button>
                  {showPaymentServicesList && (
                    <div className="payment-services-list">
                      {paymentservice?.map((service) => (
                        <label key={service.paymentTypeId}>
                          <input
                            type="checkbox"
                            value={service.paymentService}
                            checked={selectedServices.includes(
                              service.paymentService
                            )}
                            onChange={handleServiceChange}
                          />
                          <span
                            className={
                              selectedServices.includes(service.paymentService)
                                ? "selected-service"
                                : ""
                            }
                          >
                            {service.paymentService}
                          </span>
                        </label>
                      ))}
                      <button
                        className="badge badge-rounded badge-success"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Company Registered Address</td>
                <td>{merchantData?.companyAddress}</td>
                <td className="fw-bold">Pin Code</td>
                <td>{merchantData?.companyAddressPinCode}</td>
              </tr>
              <tr>
                <td className="fw-bold">City</td>
                <td>{merchantData?.companyAddressCity}</td>
                <td className="fw-bold">State</td>
                <td>{merchantData?.companyAddressState}</td>
              </tr>
              {/* Second half of the table */}
              <tr>
                <td className="fw-bold">Authorized Signatory Address</td>
                <td>{merchantData?.registeredOfficeAddress}</td>
                <td className="fw-bold">Pin Code</td>
                <td>{merchantData?.registeredOfficeAddressPinCode}</td>
              </tr>
              <tr>
                <td className="fw-bold">City</td>
                <td>{merchantData?.registeredOfficeAddressCity}</td>
                <td className="fw-bold">State</td>
                <td>{merchantData?.registeredOfficeAddressState}</td>
              </tr>
              <tr>
                <td className="fw-bold">Company Communication Address</td>
                <td>{merchantData?.communicationAddress}</td>
                <td className="fw-bold">Pin Code</td>
                <td>{merchantData?.communicationAddressPinCode}</td>
              </tr>
              <tr>
                <td className="fw-bold">City</td>
                <td>{merchantData?.communicationAddressCity}</td>
                <td className="fw-bold">State</td>
                <td>{merchantData?.communicationAddressState}</td>
              </tr>
              <tr>
                <td className="fw-bold">Mode of Registration</td>
                <td>{merchantData?.modeOfRegistration}</td>
                <td className="fw-bold">Modification Date</td>
                <td>{merchantData?.modificationDate}</td>
              </tr>
              <tr>
                <td className="fw-bold">Submission Date</td>
                <td>{merchantData?.submitDate}</td>
                <td className="fw-bold">Submission Time</td>
                <td>{merchantData?.submitTime}</td>
              </tr>
              <tr>
                <td className="fw-bold">Referred By</td>
                <td>{merchantData?.referredBy}</td>
                <td className="fw-bold">MCC Code</td>
                <td>
                  <select
                    onChange={(e) => setMccCode(e.target.value)}
                    value={mccCode}
                  >
                    <option value="">Select an MCC Code</option>
                    <option value="gaming-101">Gaming 101</option>
                    <option value="gaming-203">Gaming 203</option>
                    <option value="gaming-204">Gaming 204</option>
                    <option value="ecommerce-101">Ecommerce 101</option>
                    <option value="ecommerce-103">Ecommerce 103</option>
                    <option value="5262">5262</option>
                    <option value="5816">5816</option>
                    <option value="4900">4900</option>
                    <option value="6300">6300</option>
                  </select>
                </td>
              </tr>
              {prefix === "ALL" && (
                <tr>
                  <td className="fw-bold">Master Merchant</td>
                  <td>
                    <select
                      onChange={(e) => setMerchantMaster(e.target.value)}
                      value={merchantMaster}
                    >
                      <option value="">Select a Merchant</option>
                      {merchantMasterData?.map((merchant) => (
                        <option
                          key={merchant.masterMerchantId}
                          value={merchant.masterMerchantId}
                        >
                          {merchant.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="fw-bold">Commission Percentage</td>
                  <td>
                    <input
                      className="commision-per"
                      type="text"
                      value={commissionPercentageInput}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!inputValue || parseFloat(inputValue) >= 0) {
                          setCommissionPercentageInput(inputValue);
                        }
                      }}
                      placeholder="Enter commission percentage"
                    />
                    {parseFloat(commissionPercentageInput) < 0 && (
                      <p className="error-message">
                        Commission percentage must be positive
                      </p>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <DocumentList id={data.registerId} />
          <VideosList id={data.registerId} />
          <div className="">
            {privilagesData.includes(2593) ? (
              <div className="button-toolbar groups-btn text-end">
                <button
                  className="badge badge-rounded badge-success"
                  onClick={() => handleApprove()}
                >
                  Approve
                </button>
                <button
                  className="badge badge-rounded badge-danger"
                  onClick={() => handleReject()}
                >
                  Reject
                </button>
                <button
                  type="button"
                  class="badge badge-rounded badge-primary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            ) : null}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
export default ViewDetailsMobileApp;

import React, { useEffect, useState } from "react";
import { GET_VIDEO_FILE, GET_VIDEO_UPLOADED_BY_ID } from "../../utils/constant";
import axios from "axios";
import { Card } from "react-bootstrap";
import Loader from "../Loader/Loader";

const VideosList = ({ id }) => {
  const [videoNames, setVideoNames] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const token = localStorage.getItem("token");

  const handleViewVideos = async () => {
    try {
      const response = await axios.get(GET_VIDEO_UPLOADED_BY_ID + id, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });

      if (response?.data?.statusCode === 200) {
        setVideoNames(response?.data?.data);
      } else {
        setVideoNames([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewSingleVideo = async (filename) => {
    // const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: `Bearer ${token}`,
    };
    setShowLoader(true);
    await axios
      .get(GET_VIDEO_FILE + id, {
        responseType: "arraybuffer",
        headers,
      })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "video/mp4" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setShowLoader(false);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error("Error fetching PDF:", error.message);
      });
  };

  useEffect(() => {
    handleViewVideos();
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <>
        <div>
          <h3>Video List</h3>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Video Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {videoNames?.map((fileObject, index) => (
                <tr key={index}>
                  {/* <td>{fileObject["Orignal name"]}</td> */}
                  <td>{fileObject["fileName"]}</td>
                  <td>
                    <button
                      className="badge badge-rounded badge-warning"
                      onClick={() =>
                        handleViewSingleVideo(fileObject["Orignal name"])
                      }
                    >
                      View Video
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};

export default VideosList;
